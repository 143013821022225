export default {
  MONITORING_VIEW: "MONITORING_VIEW",

  JOURNAL_VIEW: "JOURNAL_VIEW",

  PERSON_VIEW: "PERSON_VIEW",
  PERSON_ADD: "PERSON_ADD",
  PERSON_EDIT: "PERSON_EDIT",
  PERSON_DELETE: "PERSON_DELETE",
  PERSON_RESTORE: "PERSON_RESTORE",
  PERSON_BLOCKED: "PERSON_BLOCKED",
  PERSON_BLACKED: "PERSON_BLACKED",
  PERSON_BIO_INVITE_SEND: "PERSON_BIO_INVITE_SEND",

  PERMIT_ADD: "PERMIT_ADD",
  PERMIT_EDIT: "PERMIT_EDIT",

  DEPARTMENT_VIEW: "DEPARTMENT_VIEW",
  DEPARTMENT_ADD: "DEPARTMENT_ADD",
  DEPARTMENT_EDIT: "DEPARTMENT_EDIT",
  DEPARTMENT_DELETE: "DEPARTMENT_DELETE",

  ACCESS_LEVELS_VIEW: "ACCESS_LEVELS_VIEW",
  ACCESS_LEVELS_ADD: "ACCESS_LEVELS_ADD",
  ACCESS_LEVELS_EDIT: "ACCESS_LEVELS_EDIT",
  ACCESS_LEVELS_DELETE: "ACCESS_LEVELS_DELETE",

  REQUEST_VIEW: "REQUEST_VIEW",
  REQUEST_ADD: "REQUEST_ADD",
  REQUEST_CONFIRM: "REQUEST_CONFIRM",
  REQUEST_CANCEL: "REQUEST_CANCEL",

  OPERATOR_VIEW: "OPERATOR_VIEW",
  OPERATOR_ADD: "OPERATOR_ADD",
  OPERATOR_EDIT: "OPERATOR_EDIT",
  OPERATOR_DELETE: "OPERATOR_DELETE",

  DEVICE_VIEW: "DEVICE_VIEW",
  DEVICE_ADD: "DEVICE_ADD",
  DEVICE_EDIT: "DEVICE_EDIT",
  DEVICE_ACTIONS: "DEVICE_ACTIONS",

  ZONE_VIEW: "ZONE_VIEW",
  ZONE_ADD: "ZONE_ADD",
  ZONE_EDIT: "ZONE_EDIT",
  ZONE_DELETE: "ZONE_DELETE",

  NOT_OWNER_RECORDS_VIEW: "NOT_OWNER_RECORDS_VIEW",
  NOT_OWNER_RECORDS_EDIT: "NOT_OWNER_RECORDS_EDIT",
  NOT_OWNER_RECORDS_DELETE: "NOT_OWNER_RECORDS_DELETE",

  SETTINGS_VIEW: "SETTINGS_VIEW",
};
