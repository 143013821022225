<template>
  <Alert v-if="isFailureGetProfile" type="error" prominent>
    {{ errorMessageGetProfile }}
  </Alert>

  <Alert v-if="isFailureUpdateProfile" type="error" prominent>
    {{ errorMessageUpdateProfile }}
  </Alert>

  <Form
    v-if="!isPendingGet"
    v-model="profile"
    :isPending="isPendingGetProfile || isPendingUpdateProfile"
    @SaveForm="onSave"
    @cancelForm="onCancel"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  name: "AddProfile",

  emits: ["update:modelValue"],

  components: { Form, Alert },

  data() {
    return {
      profile: {},
      object_id: null,
      id: null,

      //TODO: Убрать после рефактора пермитов
      isPendingGet: false,
    };
  },

  methods: {
    ...mapActions({
      getProfileAction: "profiles/getProfile",
      resetGetProfileActions: "profiles/resetGetProfile",

      updateProfileAction: "profiles/updateProfile",
      resetUpdateProfileAction: "profiles/resetUpdateProfile",
    }),

    getData() {
      this.id = parseInt(this.$route.params.id);
      this.object_id = parseInt(history.state.object_id);
    },

    getProfile() {
      this.isPendingGet = true;
      this.getProfileAction(this.id)
        .then(() => {
          this.profile = this.profileState;
          this.isPendingGet = false;
        })
        .catch(() => {
          this.isPendingGet = false;
        });
    },

    onSave() {
      const params = {
        data: this.profile,
        id: this.id,
      };

      this.updateProfileAction(params).then(this.onCancel);
    },

    onCancel() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      isPendingGetProfile: (state) => state.profiles.isPendingGetProfile,
      isFailureGetProfile: (state) => state.profiles.isFailureGetProfile,
      errorMessageGetProfile: (state) => state.profiles.errorMessageGetProfile,

      profileState: (state) => state.profiles.profile,

      isPendingUpdateProfile: (state) => state.profiles.isPendingUpdateProfile,
      isFailureUpdateProfile: (state) => state.profiles.isFailureUpdateProfile,
      errorMessageUpdateProfile: (state) =>
        state.profiles.errorMessageUpdateProfile,
    }),
  },

  created() {
    this.getData();
    this.getProfile();
  },

  unmounted() {
    this.resetGetProfileActions();
    this.resetUpdateProfileAction();
  },
};
</script>
