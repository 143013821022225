<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="objects"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col>
      <v-row>
        <!-- <v-col cols="12" md="auto">
              <ListActions
                :filter="filter"
                :selected="selectedObjects"
                @block="onBlock({ ids: selected })"
                @blockByFilter="onBlock({ filter: filter })"
                @unblock="onUnblock({ ids: selected })"
                @unblockByFilter="onUnblock({ filter: filter })"
                @black="onBlack({ ids: selected })"
                @blackByFilter="onBlack({ filter: filter })"
                @unblack="onUnblack({ ids: selected })"
                @unblackByFilter="onUnblack({ filter: filter })"
                @remove="onRemove({ ids: selected })"
                @removeByFilter="onRemove({ filter: filter })"
                @restore="onRestore({ ids: selected })"
                @restoreByFilter="onRestore({ filter: filter })"
                @openDepartmentDialog="isOpenDepartmentDialog = true"
                @openAccessLevelsDialog="isOpenAccessLevelsDialog = true"
                @report="report({ ...filter })"
              />
            </v-col> -->
        <v-spacer />
        <v-col v-if="isAddObject" cols="12" md="auto">
          <v-btn color="primary" @click="goToAdd">
            <v-icon start> mdi-plus </v-icon>
            {{ $t("users.addUser") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selected"
            :loading="isPending"
            :headers="headers"
            :items="objectsGetter"
            :items-per-page="pagination.limit"
            :row-props="rowProps"
          >
            <template #[`item.avatar`]="{ item }">
              <div class="my-3">
                <v-badge
                  :model-value="!!item.operator_id"
                  bordered
                  color="primary"
                  icon="mdi-star"
                >
                  <router-link
                    :to="{
                      name: $const.ROUTES.OBJECT_SHOW,
                      params: { id: item.id },
                    }"
                  >
                    <v-avatar v-if="item.photo" color="primary" size="50">
                      <ImgProtected
                        :src="`avatar/small/${item.photo}`"
                        :alt="item.full_name"
                      />
                    </v-avatar>

                    <v-avatar v-else color="primary" size="50">
                      <v-icon size="35" dark> mdi-account-circle </v-icon>
                    </v-avatar>
                  </router-link>
                </v-badge>
              </div>
            </template>

            <template #[`item.name`]="{ item }">
              <div>
                <v-chip
                  v-if="item.blocked"
                  size="x-small"
                  class="mr-1 bg-red text-white"
                >
                  <v-icon start size="x-small">mdi-cancel</v-icon>
                  {{ $t("users.blocked") }}
                </v-chip>
                <v-chip
                  v-if="item.blacklisted"
                  size="x-small"
                  color="primary"
                  class="mr-1"
                >
                  <v-icon start size="x-small">mdi-eye</v-icon>
                  {{ $t("users.blacklist") }}
                </v-chip>
              </div>
              <router-link
                class="text-primary"
                :to="{
                  name: $const.ROUTES.OBJECT_SHOW,
                  params: { id: item.id },
                }"
                >{{ item.name }}</router-link
              ><br />
              {{ item.phone }}
            </template>

            <template #[`item.profiles`]="{ item }">
              <v-row class="ma-n1 justify-center">
                <ToggleArrayItems :items="item.profiles" :minLength="3">
                  <template #default="{ items }">
                    <v-col
                      v-for="profile in items"
                      :key="profile.id"
                      cols="auto"
                      class="pa-1"
                    >
                      <v-chip
                        size="small"
                        color="primary"
                        @click="editProfile(profile.id)"
                      >
                        {{ profile.profile_type }}
                        <v-tooltip v-if="profile.is_trackable" location="top">
                          <template #activator="{ props }">
                            <v-icon v-bind="props" end>mdi-eye</v-icon>
                          </template>
                          {{ profile.trackable_comment }}
                        </v-tooltip>
                        <v-tooltip v-if="profile.is_blocked" location="top">
                          <template #activator="{ props }">
                            <v-icon v-bind="props" color="red" end
                              >mdi-account-lock-outline</v-icon
                            >
                          </template>
                          {{ profile.blocked_comment }}
                        </v-tooltip>
                      </v-chip>
                    </v-col>
                  </template>
                  <template #button="{ toggleFull, isFull }">
                    <v-col cols="auto" class="pa-1">
                      <v-btn
                        v-tooltip="
                          isFull ? $t('user.collapse') : $t('user.expand')
                        "
                        size="small"
                        color="primary"
                        variant="tonal"
                        density="comfortable"
                        :ripple="false"
                        :icon="
                          isFull ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'
                        "
                        @click.stop="toggleFull"
                      />
                    </v-col>
                  </template>
                </ToggleArrayItems>
              </v-row>
            </template>

            <template #[`item.updated_at`]="{ item }">
              {{ $moment(item.updated_at).format("DD.MM.YYYY HH:mm") }}
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip v-if="objectErrors[item.id]" location="top">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="red"
                    @click.stop="openObjectErrorLogs(item.id)"
                  >
                    <v-icon size="x-small">mdi-alert-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("users.errorLogs") }}</span>
              </v-tooltip>

              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    icon
                    variant="text"
                    color="green"
                    @click.stop="goToShow(item.id)"
                  >
                    <v-icon size="x-small"
                      >mdi-card-account-details-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ $t("user.show") }}</span>
              </v-tooltip>

              <v-tooltip v-if="isEditObject" location="top">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="primary"
                    @click.stop="goToEdit(item.id)"
                  >
                    <v-icon size="x-small">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("user.edit") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="isDeleteObject && filter.mode !== 'deleted'"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="red"
                    @click.stop="
                      onRemove({
                        ids: [item.id],
                      })
                    "
                  >
                    <v-icon size="x-small">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("button.delete") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="isRestoreObject && filter.mode === 'deleted'"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="blue"
                    @click.stop="onRestore({ ids: [item.id] })"
                  >
                    <v-icon size="x-small">mdi-restore</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("button.restore") }}</span>
              </v-tooltip>
            </template>

            <template #bottom>
              <DataTableFooterV2
                v-model="pagination"
                @update:modelValue="onPagination"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

  <!-- <SetDepartmentDialog
    :isOpen="isOpenDepartmentDialog"
    :selectedIds="selected"
    :filter="filter"
    @save="onMultiSetDepartment"
    @close="isOpenDepartmentDialog = false"
  />

  <SetAccessLevelDialog
    :isOpen="isOpenAccessLevelsDialog"
    :selectedIds="selected"
    :filter="filter"
    @save="onMultiSetAccessLevel"
    @close="isOpenAccessLevelsDialog = false"
  /> -->

  <ErrorLogsDialog
    :isOpen="isOpenObjectErrorLogs"
    :errors="objectErrors"
    @closeDialog="closeObjectErrorLogs"
  />
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

import ErrorLogsDialog from "./ErrorLogsDialog";
//import SetDepartmentDialog from "./SetDepartmentDialog";
//import SetAccessLevelDialog from "./SetAccessLevelDialog";
//import ListActions from "./ListActions";

import ImgProtected from "@/components/ImgProtected";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";
import ToggleArrayItems from "@/components/ToggleArrayItems.vue";

import ListActionsMethods from "./ListActions/utils";
import ErrorLogsDialogMethods from "./ErrorLogsDialog/utils";

import permissions from "@/mixins/permissions";

export default {
  name: "PersonsList",

  mixins: [permissions, ListActionsMethods, ErrorLogsDialogMethods],

  components: {
    ImgProtected,
    ErrorLogsDialog,
    DataTableFooterV2,
    Filter,
    ToggleArrayItems,
    //SetDepartmentDialog,
    //SetAccessLevelDialog,
    //ListActions,
  },

  data() {
    return {
      selected: [],

      headers: [
        {
          title: "ID",
          value: "id",
          width: 40,
        },
        {
          value: "avatar",
          sortable: false,
          width: 75,
        },
        {
          title: this.$t("users.fullname"),
          value: "name",
        },
        {
          title: this.$t("object.profiles"),
          value: "profiles",
          sortable: false,
          align: "center",
          cellProps: {
            class: "pa-2",
          },
        },
        {
          title: this.$t("users.updatedAt"),
          value: "updated_at",
        },
        {
          value: "actions",
          sortable: false,
          align: "end",
          width: 162,
        },
      ],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      getObjectsAction: "object/getObjectsList",
      report: "persons/report",
      remove: "persons/remove",
      block: "persons/block",
      unblock: "persons/unblock",
      black: "persons/black",
      unblack: "persons/unblack",
      restore: "persons/restore",

      resetDeleteObjectAction: "object/resetDeleteObject",
      deleteObjectAction: "object/deleteObject",

      setStatePageDataAction: "stateObjects/setPageData",
      setStateFilterAction: "stateObjects/setFilter",
      setStatePaginationAction: "stateObjects/setPagination",
    }),

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния таблицы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },

    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };

      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getObjects();
    },

    getObjects() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getObjectsAction(params).then((response) => {
        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;

          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }
      });
    },

    goToAdd() {
      this.$router.push({
        name: this.$const.ROUTES.ADD_OBJECT,
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_OBJECT,
        params: {
          id,
        },
      });
    },

    goToShow(id) {
      this.$router.push({
        name: this.$const.ROUTES.OBJECT_SHOW,
        params: { id },
      });
    },

    editProfile(id) {
      if (this.isEditObject) {
        this.$router.push({
          name: this.$const.ROUTES.EDIT_PROFILE,
          params: {
            id,
          },
          state: {
            object_id: this.id,
          },
        });
      }
    },

    rowProps(item) {
      const props = {};

      if (
        this.$vuetify.display.mobile &&
        item.item.id &&
        this.can(this.$const.RULES.PERSON_VIEW)
      ) {
        props.onClick = () => this.goToShow(item.item.id);
      }

      return props;
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.persons.isPending,

      statePageData: (state) => state.stateObjects.data,
      stateFilter: (state) => state.stateObjects.filter,
      statePagination: (state) => state.stateObjects.pagination,
    }),

    ...mapGetters({
      objectsGetter: "object/getObjectsToShow",
    }),

    isAddObject() {
      return this.can(this.$const.RULES.PERSON_ADD);
    },

    isEditObject() {
      return this.can(this.$const.RULES.PERSON_EDIT);
    },

    selectedObjects() {
      return this.objectsGetter.filter((object) =>
        this.selected.includes(object.id)
      );
    },
  },

  created() {
    this.getState();
    this.getObjects();
  },

  unmounted() {
    this.resetDeleteObjectAction();
  },
};
</script>
