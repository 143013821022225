<template>
  <v-sheet v-if="Object.keys(object).length">
    <v-row v-for="profile in object.profiles" :key="profile.id" type="flex">
      <v-col>
        <v-card elevation="2" :ripple="false" @click="editProfile(profile.id)">
          <v-card-title
            ><v-row>
              <v-col>{{ profile.type_name }}</v-col>
              <v-spacer />
              <v-col v-if="isPersonDelete" cols="auto">
                <v-btn
                  :ripple="false"
                  color="red"
                  variant="text"
                  density="comfortable"
                  icon="mdi-delete-outline"
                  location="top"
                  @click.stop="deleteProfile(profile.id)"
                />
              </v-col> </v-row
          ></v-card-title>
          <v-card-text class="pt-3">
            <v-row>
              <v-col cols="6" class="grey--text">ID</v-col>
              <v-col cols="6">{{ profile.id }}</v-col>
            </v-row>
            <v-row v-if="profile.departments_id">
              <v-col cols="6" class="grey--text">{{
                $t("profile.department")
              }}</v-col>
              <v-col cols="6">{{ profile.department_name }}</v-col>
            </v-row>
            <CustomFields
              v-model="profile.values"
              :fields="curProfileType(profile.profile_types_id)"
            />
            <v-row v-if="profile.permits?.length">
              <v-col cols="6" class="grey--text">{{
                $t("profile.permits")
              }}</v-col>
              <v-col cols="6">
                <v-chip
                  v-for="permit in profile.permits"
                  :key="permit.id"
                  class="mr-2"
                >
                  {{ permit.id }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col cols="auto">
                <template v-if="isPersonBlacked">
                  <v-tooltip v-if="!profile.is_trackable" location="top">
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ml-0 mr-2"
                        variant="text"
                        density="comfortable"
                        :ripple="false"
                        icon="mdi-eye-off-outline"
                        color="primary"
                        @click.stop="onBlack(profile.id)"
                      />
                    </template>
                    <span>{{ $t("button.add_blacklist") }}</span>
                  </v-tooltip>
                  <v-tooltip v-else location="top">
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ml-0 mr-2"
                        variant="text"
                        density="comfortable"
                        :ripple="false"
                        icon="mdi-eye-outline"
                        color="green"
                        @click.stop="unBlackProfile(profile.id)"
                      />
                    </template>
                    <span
                      >{{ $t("button.remove_blacklist") }} <br />
                      {{ profile.trackable_comment }}</span
                    >
                  </v-tooltip>
                </template>
                <template v-if="isPersonBlocked">
                  <v-tooltip v-if="!profile.is_blocked" location="top">
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ml-0 mr-2"
                        variant="text"
                        density="comfortable"
                        :ripple="false"
                        icon="mdi-account-lock-open-outline"
                        color="primary"
                        @click.stop="onBlock(profile.id)"
                      />
                    </template>
                    <span>{{ $t("button.block") }}</span>
                  </v-tooltip>
                  <v-tooltip v-else location="top">
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ml-0 mr-2"
                        variant="text"
                        density="comfortable"
                        :ripple="false"
                        icon="mdi-account-lock-outline"
                        color="green"
                        @click.stop="unBlockProfile(profile.id)"
                      />
                    </template>
                    <span
                      >{{ $t("button.unblock") }} <br />
                      {{ profile.blocked_comment }}</span
                    >
                  </v-tooltip>
                </template>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!object.deleted_at && curProfileTypes.length">
      <v-spacer />
      <v-col cols="auto">
        <v-menu v-if="curProfileTypes.length" offset-y start>
          <template #activator="{ props }">
            <v-btn v-bind="props" variant="text" color="success">
              <v-icon start>mdi-account-plus-outline</v-icon>
              {{ $t("profile.add_profile") }}
            </v-btn>
          </template>
          <v-list density="comfortable">
            <v-list-item
              v-for="type in curProfileTypes"
              :key="type.id"
              @click="goAddProfile(type.id)"
            >
              <v-list-item-title>
                {{ type.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-sheet>
  <v-row v-else>
    <v-spacer />
    <v-col cols="auto">
      {{ $t("object.emptyProfiles") }}
    </v-col>
    <v-spacer />
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";

import { CustomFields } from "@/components/ObjectsTools";

import permissions from "@/mixins/permissions";

export default {
  components: { CustomFields },

  mixins: [permissions],

  props: {
    object: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      profileTypes: [],
    };
  },

  methods: {
    ...mapActions({
      getProfileTypesAction: "profiles/getProfilesTypes",

      deleteProfileAction: "profiles/deleteProfile",

      updateProfileAction: "profiles/updateProfile",
    }),

    getProfileTypes() {
      this.getProfileTypesAction().then(() => {
        this.profileTypes = this.profileTypesState;
      });
    },

    goAddProfile(type_id) {
      this.$router.push({
        name: this.$const.ROUTES.ADD_PROFILE,
        params: {
          object_id: this.object.id,
        },
        state: { type_id },
      });
    },

    editProfile(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_PROFILE,
        params: {
          id,
        },
        state: {
          object_id: this.object.id,
        },
      });
    },

    deleteProfile(id) {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: () => {
          this.deleteProfileAction(id).then(this.updateObject);
        },
      });
    },

    onBlack(id) {
      this.$root.$prompt({
        title: this.$t("button.add_blacklist"),
        message: this.$t("message.blackCause"),
        btnAgree: this.$t("button.accept"),
        width: 600,
        useTextField: true,
        textField: "",

        agree: (data) => {
          this.blackProfile(id, data.textField);
        },
      });
    },

    blackProfile(id, text) {
      const params = {
        data: {
          id,
          is_trackable: true,
          trackable_comment: text,
        },
        id: id,
      };

      this.updateProfileAction(params).then(this.updateObject);
    },

    unBlackProfile(id) {
      const params = {
        data: {
          id,
          is_trackable: false,
          trackable_comment: "",
        },
        id: id,
      };

      this.updateProfileAction(params).then(this.updateObject);
    },

    onBlock(id) {
      this.$root.$prompt({
        title: this.$t("button.block"),
        message: this.$t("message.blockCause"),
        btnAgree: this.$t("button.accept"),
        width: 600,
        useTextField: true,
        textField: "",

        agree: (data) => {
          this.blockProfile(id, data.textField);
        },
      });
    },

    blockProfile(id, text) {
      const params = {
        data: {
          id,
          is_blocked: true,
          blocked_comment: text,
        },
        id: id,
      };

      this.updateProfileAction(params).then(this.updateObject);
    },

    unBlockProfile(id) {
      const params = {
        data: {
          id,
          is_blocked: false,
          blocked_comment: "",
        },
        id: id,
      };

      this.updateProfileAction(params).then(this.updateObject);
    },

    curProfileType(id) {
      const curProfileType = this.profileTypesState.find(
        (type) => type.id == id
      );

      return curProfileType?.fields;
    },

    updateObject() {
      this.$emit("updateObject");
    },
  },

  computed: {
    ...mapState({
      profileTypesState: (state) => state.profiles.profilesTypes,
    }),

    curProfileTypes() {
      const usedIdes = this.object.profiles?.map(
        (profile) => profile.profile_types_id
      );

      return (
        this.profileTypes.filter((type) => !usedIdes.includes(type.id)) ?? []
      );
    },

    isPersonDelete() {
      return this.can(this.$const.RULES.PERSON_DELETE);
    },
    isPersonBlacked() {
      return this.can(this.$const.RULES.PERSON_BLACKED);
    },
    isPersonBlocked() {
      return this.can(this.$const.RULES.PERSON_BLOCKED);
    },
  },

  created() {
    this.getProfileTypes();
  },
};
</script>
