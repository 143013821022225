<template>
  <v-card class="overflow-visible" elevation="2">
    <v-tabs show-arrows color="primary">
      <v-tab v-for="(tab, i) in tabByPermission" :key="i" :to="tab.to">
        <v-icon start>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-card-text>
      <router-view />
    </v-card-text>
  </v-card>
</template>

<script>
import permissions from "@/mixins/permissions";

export default {
  name: "AdmissionPage",

  mixins: [permissions],

  data() {
    return {
      tabs: [
        {
          name: this.$t("object.objects"),
          to: { name: this.$const.ROUTES.OBJECT_LIST },
          icon: "mdi-account-multiple",
        },
        {
          name: this.$t("menu.departments"),
          to: { name: this.$const.ROUTES.DEPARTMENTS },
          icon: "mdi-account-details",
          permission: this.$const.RULES.DEPARTMENT_VIEW,
        },
        {
          name: this.$t("menu.operators"),
          to: { name: this.$const.ROUTES.OPERATORS },
          icon: "mdi-account-star",
          permission: this.$const.RULES.OPERATOR_VIEW,
        },
        {
          name: this.$t("menu.object-type"),
          to: { name: this.$const.ROUTES.OBJECT_TYPE },
          icon: "mdi-folder-account",
        },
        {
          name: this.$t("menu.profile-type"),
          to: { name: this.$const.ROUTES.PROFILE_TYPE },
          icon: "mdi-clipboard-account-outline",
        },
        // {
        //   name: this.$t("menu.permits"),
        //   to: { name: this.$const.ROUTES.PERMITS },
        //   icon: "mdi-account-star",PROFILE_TYPE
        // },
      ],
    };
  },

  watch: {
    $route: {
      handler() {
        if (this.tabByPermission.length) {
          this.redirect();
        }
      },
      immediate: true,
    },
  },

  methods: {
    redirect() {
      if (this.$route.name === this.$const.ROUTES.ADMISSION) {
        this.$router.replace(this.tabByPermission[0].to);
      }
    },
  },

  computed: {
    tabByPermission() {
      return this.tabs.filter((tab) => this.can(tab.permission));
    },
  },
};
</script>
