<template>
  <Alert v-if="isFailureGetProfilesTypes" type="error">
    {{ errorMessageGetProfilesTypes }}
  </Alert>
  <Alert v-if="isFailureDepartments" type="error">
    {{ errorMessageDepartments }}
  </Alert>
  <Alert v-if="isFailureDeleteProfileState" type="error">
    {{ errorMessageDeleteProfileState }}
  </Alert>

  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card
      elevation="2"
      :disabled="disabled"
      :loading="
        isPending ||
        isPendingGetProfilesTypes ||
        isLoadingDepartments ||
        isPendingDeleteProfileState
      "
    >
      <template #loader="{ isActive }">
        <v-progress-linear
          v-if="isActive"
          color="primary"
          height="10"
          indeterminate
        />
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <label>{{ $t("user.department") }}</label>
            <el-tree-select
              v-model="departmentComputed"
              :data="departments"
              check-on-click-node
              node-key="id"
              size="large"
              :props="{ label: 'name', children: 'children' }"
              placeholder=""
              check-strictly
              filterable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CustomFields
              v-model="customFieldsComputed"
              :fields="curProfileType?.fields"
              :show="false"
            /> </v-col
        ></v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="accessModesComputed"
              multiple
              return-object
              chips
              :label="$t('access-levels.accessModes')"
              item-title="name"
              item-value="id"
              color="primary"
              density="comfortable"
              variant="outlined"
              hide-details
              :loading="isPendingGetAccessModesState"
              :items="dataAccessModesState"
            >
              <template #append-inner>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      color="grey-lighten-1"
                      variant="text"
                      density="compact"
                      icon
                      @click="getAccessModes"
                    >
                      <v-icon>mdi-autorenew</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("setting.dataUpdate") }}</span>
                </v-tooltip>
              </template>
              <template #append>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      variant="text"
                      density="compact"
                      icon
                      color="primary"
                      @click="goToAddAccessMode"
                    >
                      <v-icon>mdi-calendar-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("access-levels.addAccessMode") }}</span>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="useDateRangeComputed"
              density="compact"
              color="primary"
              hide-details
            >
              <template #label>
                {{ this.$t("profile.durationTime") }}
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-icon class="ml-2" v-bind="props" size="small"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("profile.mesTimeZoneWarn") }}</span>
                </v-tooltip>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row v-if="useDateRangeComputed">
          <v-col>
            <DateRange
              v-model="dateRangeComputed"
              :settings="{ size: 'large', clearable: true, md: 6 }"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <PermitList
              :data="modelValue.permits"
              :curObject="{ uid: modelValue.objects_uid }"
              :objectId="modelValue.objects_id"
              @updatePermits="onChangePermits"
              @editedStatus="isPermitsEdited = $event"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn @click="onCancelForm">
          <v-icon start>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-col v-if="modelValue?.id" cols="auto">
          <v-btn variant="flat" color="error" @click="onRemove">
            {{ $t("button.delete") }}
          </v-btn>
        </v-col>
        <v-btn
          variant="flat"
          color="green"
          :disabled="isPermitsEdited"
          @click="setData"
        >
          <v-icon start>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { CustomFields } from "@/components/ObjectsTools";
import PermitList from "@/components/Permits";
import Alert from "@/components/Alert";
import DateRange from "@/components/DateRange";

export default {
  name: "PersonForm",

  emits: ["cancelForm", "SaveForm", "update:modelValue"],

  components: {
    CustomFields,
    PermitList,
    DateRange,
    Alert,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    defaultTypeId: {
      type: Number,
      default: null,
    },

    isPending: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: false,
      isPermitsEdited: false,

      activeDateRange: undefined,
    };
  },

  methods: {
    ...mapActions({
      getProfilesTypesAction: "profiles/getProfilesTypes",
      resetGetProfilesTypesAction: "profiles/resetGetProfilesTypes",

      getDepartments: "departments/getTree",

      resetDeleteProfileAction: "profiles/resetDeleteProfile",
      deleteProfileAction: "profiles/deleteProfile",

      getAccessModes: "accessModes/getAccessModes",
    }),

    goToAddAccessMode() {
      let route = this.$router.resolve({
        name: this.$const.ROUTES.ADD_ACCESS_MODES,
      });

      window.open(route.href, "_blank");
    },

    getProfileTypes() {
      this.getProfilesTypesAction().then(() => {
        if (this.modelValue.profile_types_id) return;

        let curType = this.profileTypesState.find(
          (type) => type.id == this.defaultTypeId
        );

        const curValues = [];

        curType.fields.forEach((field) => {
          curValues.push({
            type: field.type,
            name: field.name,
            value: field.value,
            input_value: field.input_value,
          });
        });

        const params = {
          ...this.modelValue,
          values: curValues,
          profile_types_id: curType.id,
        };

        this.$emit("update:modelValue", params);
      });
    },

    onChangePermits(permits) {
      const params = {
        ...this.modelValue,
        permits: permits,
      };

      this.$emit("update:modelValue", params);
    },

    onRemove() {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.deleteProfile();
          this.onCancelForm();
        },
      });
    },

    deleteProfile() {
      return this.deleteProfileAction(this.modelValue.id);
    },

    onCancelForm() {
      this.$emit("cancelForm");
    },

    updateModelValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },

    async setData() {
      let valid = false;
      await this.$refs.form.validate().then((result) => {
        valid = result.valid;
      });
      if (!valid) return;

      this.$emit("SaveForm");
    },
  },

  computed: {
    ...mapState({
      isPendingGetProfilesTypes: (state) =>
        state.profiles.isPendingGetProfilesTypes,
      isFailureGetProfilesTypes: (state) =>
        state.profiles.isFailureGetProfilesTypes,
      errorMessageGetProfilesTypes: (state) =>
        state.profiles.errorMessageGetProfilesTypes,

      profileTypesState: (state) => state.profiles.profilesTypes,

      isLoadingDepartments: (state) => state.departments.isLoading,
      isFailureDepartments: (state) => state.departments.isFailure,
      errorMessageDepartments: (state) => state.departments.errorMessage,

      departments: (state) => state.departments.tree,

      isPendingDeleteProfileState: (state) =>
        state.profiles.isPendingDeleteProfile,
      isFailureDeleteProfileState: (state) =>
        state.profiles.isFailureDeleteProfile,
      errorMessageDeleteProfileState: (state) =>
        state.profiles.errorMessageDeleteProfile,

      isPendingGetAccessModesState: (state) =>
        state.accessModes.isPendingGetAccessModes,
      dataAccessModesState: (state) => state.accessModes.dataAccessModes,
    }),

    curProfileType() {
      return this.profileTypesState.find(
        (type) => type.id == this.modelValue.profile_types_id
      );
    },

    accessModesComputed: {
      get() {
        return this.modelValue.access_modes;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          access_modes: newValue,
        };

        this.$emit("update:modelValue", params);
      },
    },

    useDateRangeComputed: {
      get() {
        return (
          this.activeDateRange ??
          (!!this.modelValue.start_time || !!this.modelValue.expire_time)
        );
      },
      set(newValue) {
        this.activeDateRange = newValue;

        if (!newValue) {
          const params = {
            ...this.modelValue,
            start_time: "",
            expire_time: "",
          };
          this.$emit("update:modelValue", params);
        }
      },
    },

    customFieldsComputed: {
      get() {
        return this.modelValue.values;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          values: newValue,
        };

        this.$emit("update:modelValue", params);
      },
    },

    departmentComputed: {
      get() {
        return this.modelValue.departments_id;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          departments_id: newValue,
        };

        this.$emit("update:modelValue", params);
      },
    },

    dateRangeComputed: {
      get() {
        return [this.modelValue.start_time, this.modelValue.expire_time];
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          start_time: newValue[0] || "",
          expire_time: newValue[1] || "",
        };

        this.$emit("update:modelValue", params);
      },
    },
  },

  created() {
    this.getProfileTypes();
    this.getDepartments();
    this.getAccessModes();
  },

  unmounted() {
    this.resetGetProfilesTypesAction();
    this.resetDeleteProfileAction();
  },
};
</script>
