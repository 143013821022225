<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="departments"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col>
      <v-row v-if="isFailureGetDepartmentsListState">
        <v-col cols="12">
          <Alert type="error">
            {{ errorMessageGetDepartmentsListState }}
          </Alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :loading="isPendingGetDepartmentsListState"
            :headers="headers"
            :items="departmentsList"
            :items-per-page="pagination.limit"
            :row-props="rowProps"
            disable-sort
          >
            <template #loader="{ isActive }">
              <v-progress-linear
                v-if="isActive"
                color="primary"
                height="10"
                indeterminate
              />
            </template>

            <template #[`item.name`]="{ item }">
              <router-link
                v-if="can($const.RULES.DEPARTMENT_EDIT)"
                class="text-primary"
                :to="{
                  name: $const.ROUTES.EDIT_DEPARTMENT,
                  params: {
                    id: item.id,
                  },
                }"
                >{{ item.name }}</router-link
              >
              <template v-else>
                <div>
                  {{ item.name }}
                </div>
              </template>
            </template>

            <template #[`item.parent`]="{ item }">
              <template v-if="item.parent">
                <router-link
                  v-if="can($const.RULES.DEPARTMENT_EDIT)"
                  class="text-primary"
                  :to="{
                    name: $const.ROUTES.EDIT_DEPARTMENT,
                    params: {
                      id: item.parent.id,
                    },
                  }"
                >
                  {{ item.parent.name }}
                </router-link>
                <template v-else>
                  {{ item.parent.name }}
                </template>
              </template>
              <span v-else>
                {{ $t("departments.withoutParent") }}
              </span>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip
                v-if="can($const.RULES.DEPARTMENT_EDIT)"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="primary"
                    @click.stop="goToEdit(item.id)"
                  >
                    <v-icon size="x-small">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("departments.change") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="can($const.RULES.DEPARTMENT_DELETE)"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="red"
                    @click.stop="onRemove(item.id)"
                  >
                    <v-icon size="small">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("departments.delete") }}</span>
              </v-tooltip>
            </template>

            <template #bottom>
              <DataTableFooterV2
                v-model="pagination"
                @update:modelValue="onPagination"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";

import permissions from "@/mixins/permissions";

export default {
  name: "DepartmentsList",

  mixins: [permissions],

  components: { Filter, Alert, DataTableFooterV2 },

  data() {
    return {
      departmentsList: [],

      headers: [
        {
          title: "ID",
          value: "id",
        },
        {
          title: this.$t("departments.name"),
          value: "name",
        },
        {
          title: this.$t("departments.parent"),
          value: "parent",
        },
        {
          value: "actions",
          align: "end",
          width: 100,
        },
      ],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      resetGetDepartmentsListAction: "departments/resetGetDepartmentsList",
      getDepartmentsListAction: "departments/getDepartmentsList",

      remove: "department/remove",

      setStatePageDataAction: "stateDepartments/setPageData",
      setStateFilterAction: "stateDepartments/setFilter",
      setStatePaginationAction: "stateDepartments/setPagination",
    }),

    getDepartmentsList() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getDepartmentsListAction(params).then((response) => {
        this.departmentsList = this.departmentsListState;

        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;

          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_DEPARTMENT,
        params: {
          id,
        },
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(id);
          this.getDepartmentsList();
        },
      });
    },

    goToUser(item) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_DEPARTMENT,
        params: {
          id: item.item.id,
        },
      });
    },

    rowProps(item) {
      const props = {};
      if (
        this.$vuetify.display.mobile &&
        item.item.id &&
        this.can(this.$const.RULES.DEPARTMENT_EDIT)
      ) {
        props.onClick = () => this.goToUser(item);
      }

      return props;
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния таблицы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },

    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };

      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getDepartmentsList();
    },
  },

  computed: {
    ...mapState({
      isPendingGetDepartmentsListState: (state) =>
        state.departments.isPendingGetDepartmentsList,
      isFailureGetDepartmentsListState: (state) =>
        state.departments.isFailureGetDepartmentsList,
      errorMessageGetDepartmentsListState: (state) =>
        state.departments.errorMessageGetDepartmentsList,

      departmentsListState: (state) => state.departments.departmentsList,

      statePageData: (state) => state.stateDepartments.data,
      stateFilter: (state) => state.stateDepartments.filter,
      statePagination: (state) => state.stateDepartments.pagination,
    }),
  },

  created() {
    this.getState();
    this.getDepartmentsList();
  },

  unmounted() {
    this.resetGetDepartmentsListAction();
  },
};
</script>
