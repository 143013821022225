<template>
  <v-row>
    <v-col cols="12" md="5">
      <v-select
        v-model="typeComputed"
        :items="types"
        :label="$t('permits.passType')"
        item-title="text"
        item-value="value"
        density="comfortable"
        color="primary"
        variant="outlined"
        hide-details="auto"
        @update:modelValue="onChange"
      />
    </v-col>

    <v-col v-if="type === 'qr'">
      <QrNumberInput
        ref="qrNumber"
        v-model="numberComputed"
        :error-messages="numberErrors"
        @openQrCodeDialog="$emit('openQrCodeDialog', $event)"
        @generateQRCode="generateQRCode"
      />
    </v-col>

    <v-col v-if="type === 'card'">
      <CardNumberInput
        ref="cardNumber"
        color="primary"
        v-model="numberComputed"
        :error-messages="numberErrors"
      />
    </v-col>

    <v-col v-if="type === 'accompany'">
      <AccompanyingInput
        v-model="numberComputed"
        v-model:modelObjects="metaObjectComputed"
        :disabledKeys="disabledItem"
        itemKey="uid"
        :error-messages="numberErrors"
      />
    </v-col>

    <v-col cols="auto">
      <v-btn icon="mdi-delete" variant="text" color="red" @click="remove">
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapActions } from "vuex";

import QrNumberInput from "./QrNumberInput";
import CardNumberInput from "./CardNumberInput";
import AccompanyingInput from "./AccompanyingInput";

import validator from "./validator";

export default {
  name: "PermitCardToken",

  mixins: [validator],

  components: {
    QrNumberInput,
    CardNumberInput,
    AccompanyingInput,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    objectId: {
      type: [Number, String],
      default: null,
    },

    allTokens: {
      type: Array,
      default: () => [],
    },

    curObject: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loopId: this.data.loopId,
      type: this.data.type,
      number: this.data.number,
      accompanying: this.data.accompanying,
      name: this.data.name,
      meta: this.data.meta,

      isOpenGenerateNumberDialog: false,

      types: [
        { text: this.$t("permits.card"), value: "card" },
        { text: this.$t("permits.qr"), value: "qr" },
        { text: this.$t("permits.accompany"), value: "accompany" },
      ],
    };
  },

  methods: {
    ...mapActions({
      checkToken: "persons/checkToken",
    }),

    onChange() {
      this.$emit("changeToken", {
        loopId: this.loopId,
        type: this.type,
        number: this.number,
        name: this.name,
        meta: this.meta,
      });
    },

    remove() {
      this.$emit("remove", this.loopId);
    },

    generateQRCode() {
      this.number = uuid();

      this.v$.number.$touch();
      this.onChange();
    },

    disabledItem(item) {
      return this.disabledUids.includes(item.uid) || item.id == this.objectId;
    },
  },

  computed: {
    disabledUids() {
      const curDisabledUids = [];

      this.allTokens.forEach((token) => {
        if (token.type == "accompany" && token.number) {
          curDisabledUids.push(token.number);
        }
      });

      if (this.curObject?.id) {
        curDisabledUids.push(this.curObject.id);
      }

      return curDisabledUids;
    },
    typeComputed: {
      get() {
        return this.type;
      },
      set(newValue) {
        this.number = "";

        this.meta = {};

        this.type = newValue;

        this.v$.$reset();

        this.onChange();
      },
    },

    numberComputed: {
      get() {
        return this.number;
      },
      set(newValue) {
        this.number = newValue;

        console.log(this.number);
        this.v$.number.$touch();
        this.onChange();
      },
    },

    metaObjectComputed: {
      get() {
        if (this.meta && Object.keys(this.meta).length) {
          return this.meta;
        } else {
          return {};
        }
      },
      set(newValue) {
        this.name = newValue?.name;
        this.meta = newValue;
        this.onChange();
      },
    },
  },
};
</script>
