<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="accesslevels"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col>
      <v-row v-if="isFailureGetAccessLevelsTree">
        <v-col>
          <Alert type="error">
            {{ errorMessageGetAccessLevelsTree }}
          </Alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-skeleton-loader
            v-if="isPendingGetAccessLevelsTreeState"
            class="mx-auto"
            type="list-item@3"
          />

          <el-tree
            v-else
            ref="accessLevelsTree"
            :data="accessLevelsTree"
            :props="{ label: 'name' }"
            :expand-on-click-node="false"
            :filter-node-method="searchTreeFilter"
          >
            <template #="{ data }">
              <v-tooltip
                v-if="can($const.RULES.ACCESS_LEVELS_DELETE)"
                location="top"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="red"
                    @click="onRemove(data.id)"
                  >
                    <v-icon size="x-small">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("access-levels.delete") }}</span>
              </v-tooltip>
              <v-tooltip v-if="data.is_guest" location="top">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" size="x-small"
                    >mdi-account-clock</v-icon
                  >
                </template>
                <span>{{ $t("access-levels.isGuest") }}</span>
              </v-tooltip>
              <router-link
                v-if="can($const.RULES.ACCESS_LEVELS_EDIT)"
                class="text-primary"
                :to="{
                  name: $const.ROUTES.EDIT_ACCESS_LEVEL,
                  params: { id: data.id },
                }"
              >
                {{ data.name }}
              </router-link>
              <template v-else>
                <div class="text-primary">
                  {{ data.name }}
                </div>
              </template>
            </template>
          </el-tree>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <div></div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import Filter from "@/components/Filter";

import permissions from "@/mixins/permissions";

export default {
  name: "AccessLevelsTree",

  mixins: [permissions],

  components: { Alert, Filter },

  data() {
    return {
      accessLevelsTree: [],

      search: "",

      filter: {},
      pagination: {},
      pageData: {},
    };
  },

  methods: {
    ...mapActions({
      resetGetAccessLevelsTreeAction: "accessLevels/resetGetAccessLevelsTree",
      getAccessLevelsTreeAction: "accessLevels/getAccessLevelsTree",

      remove: "accessLevel/remove",

      setStatePageDataAction: "stateAccessLevels/setPageData",
      setStateFilterAction: "stateAccessLevels/setFilter",
      setStatePaginationAction: "stateAccessLevels/setPagination",
    }),

    getAccessLevels() {
      const params = {
        ...this.filter,
      };

      this.getAccessLevelsTreeAction(params).then(() => {
        this.accessLevelsTree = this.accessLevelsTreeState;
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ACCESS_LEVEL,
        params: { id },
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(id);
          this.get();
        },
      });
    },

    getParentNameById(id) {
      const accessLevel = this.accessLevels.filter(
        (accessLevel) => accessLevel.id === id
      );

      return accessLevel.name;
    },

    searchTreeFilter(value, data) {
      if (!value) return true;
      return data.name.includes(value);
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния таблицы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },

    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };

      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getAccessLevels();
    },
  },

  computed: {
    ...mapState({
      isPendingGetAccessLevelsTreeState: (state) =>
        state.accessLevels.isPendingGetAccessLevelsTree,
      isFailureGetAccessLevelsTree: (state) =>
        state.accessLevels.isFailureGetAccessLevelsTree,
      errorMessageGetAccessLevelsTree: (state) =>
        state.accessLevels.errorMessageGetAccessLevelsTree,

      accessLevelsTreeState: (state) => state.accessLevels.accessLevelsTree,

      statePageData: (state) => state.stateAccessLevels.data,
      stateFilter: (state) => state.stateAccessLevels.filter,
      statePagination: (state) => state.stateAccessLevels.pagination,
    }),
  },

  created() {
    this.getState();
    this.getAccessLevels();
  },

  unmounted() {
    this.resetGetAccessLevelsTreeAction();
  },
};
</script>
