<template>
  <v-row v-if="isFailureGetObjectState">
    <v-col>
      <Alert type="error">
        {{ errorMessageGetObjectState }}
      </Alert>
    </v-col>
  </v-row>

  <v-row v-if="object.deleted_at">
    <v-col>
      <Alert type="warning">
        {{ $t("object.messageIsDelete") }}
      </Alert>
    </v-col>
  </v-row>

  <v-row v-if="Object.keys(object).length" type="flex">
    <v-col cols="12" md="4">
      <v-card elevation="0">
        <ImgProtected
          v-if="object.photo"
          :src="`/avatar/profile/${object.photo}`"
        />

        <v-img v-else height="200" class="primary">
          <template #placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-icon size="150" color="primary"> mdi-account-circle </v-icon>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-col>

    <v-col cols="12" md="8">
      <v-card elevation="0">
        <v-card-title>
          <v-row>
            <v-col cols="auto text-primary text-h4">
              {{ object.name }}
            </v-col>
            <v-spacer />
            <v-col v-if="!object.deleted_at && isAddToBio" cols="auto"
              ><BioAlert :id="id" :userName="`${object.name}`"
            /></v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="6" class="grey--text">ID</v-col>
            <v-col cols="6">{{ object.id }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="grey--text">UID</v-col>
            <v-col cols="6">{{ object.uid }}</v-col>
          </v-row>
          <CustomFields
            v-model="object.values"
            :fields="curObjectType?.fields"
          />
          <v-row>
            <v-col cols="6" class="grey--text">{{
              $t("user.createdAt")
            }}</v-col>
            <v-col cols="6">{{
              $moment(object.created_at).format("DD.MM.YYYY HH:mm")
            }}</v-col>
          </v-row>
          <v-row v-if="object.updated_at">
            <v-col cols="6" class="grey--text">{{
              $t("user.updatedAt")
            }}</v-col>
            <v-col cols="6">{{
              $moment(object.updated_at).format("DD.MM.YYYY HH:mm")
            }}</v-col>
          </v-row>
          <v-row>
            <v-col class="text-h6 pb-0" cols="12">
              {{ `${$t("object.profiles")}:` }}
            </v-col>
            <v-col cols="12">
              <ProfilesList :object="object" @updateObject="getObject" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="auto">
      <v-btn variant="plain" @click="goBack">
        <v-icon start>mdi-cancel</v-icon>
        {{ $t("button.cancel") }}
      </v-btn>
    </v-col>
    <v-spacer />
    <v-col cols="auto">
      <v-btn color="green" variant="flat" @click="goToEdit">
        <v-icon start>mdi-pencil</v-icon>
        {{ $t("user.edit") }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";

import BioAlert from "./components/BioAlert";
import ProfilesList from "./ProfilesList.vue";

import ImgProtected from "@/components/ImgProtected";
import { CustomFields } from "@/components/ObjectsTools";
import Alert from "@/components/Alert";

import permissions from "@/mixins/permissions";

export default {
  components: { ImgProtected, CustomFields, ProfilesList, BioAlert, Alert },

  mixins: [permissions],

  data() {
    return {
      id: null,

      object: {},
    };
  },

  methods: {
    ...mapActions({
      resetGetObjectAction: "object/resetGetObject",
      getObjectAction: "object/getObject",

      getObjectTypesAction: "object/getObjectTypes",
    }),

    getId() {
      this.id = parseInt(this.$route.params.id);
    },

    getObject() {
      this.getObjectAction(this.id).then(() => {
        this.object = this.objectGetter;
      });
    },

    goToEdit() {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_OBJECT,
      });
    },

    goBack() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      objectState: (state) => state.object.object,

      isPendingGetObjectState: (state) => state.object.isPendingGetObject,
      isFailureGetObjectState: (state) => state.object.isFailureGetObject,
      errorMessageGetObjectState: (state) => state.object.errorMessageGetObject,

      objectTypesState: (state) => state.object.objectTypes,
    }),

    ...mapGetters({
      objectGetter: "object/getObjectToShow",
    }),

    curObjectType() {
      return this.objectTypesState.find(
        (type) => type.id == this.object.object_types_id
      );
    },

    isAddToBio() {
      return this.can(this.$const.RULES.PERSON_BIO_INVITE_SEND);
    },
  },

  created() {
    this.getId();
    this.getObject();
    this.getObjectTypesAction();
  },

  unmounted() {
    this.resetGetObjectAction();
  },
};
</script>
