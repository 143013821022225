<template>
  <v-card class="mx-auto" outlined :color="cardColor" :disabled="readonly">
    <!-- Edit mode -->
    <v-form v-if="isEdit" ref="form">
      <v-card-text>
        <v-row>
          <v-col>
            <v-card elevation="0">
              <v-card-title>
                <v-label class="text-subtitle-1 font-weight-bold"
                  >{{ $t("permits.accessLevel") }} *</v-label
                >
              </v-card-title>
              <v-card-text>
                <div
                  :class="
                    !!permitAccessErrors.length ? 'el-tree-select--error' : ''
                  "
                >
                  <AccessModes
                    v-model="permitAccess"
                    v-model:accessLevelObjects="permitAccessObjects"
                    :accessLevels="accessLevels"
                    @update:modelValue="v$.permitAccess.$touch()"
                  >
                    <template #error-messages>
                      <v-messages
                        v-if="!!permitAccessErrors.length"
                        :active="!!permitAccessErrors.length"
                        :messages="permitAccessErrors"
                        color="error"
                        class="my-2 px-2"
                      />
                    </template>
                  </AccessModes>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="isShowTokens">
          <v-col>
            <v-card elevation="0">
              <v-card-title>
                <v-label class="text-subtitle-1 font-weight-bold">
                  {{ $t("permits.pass") }}
                </v-label>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-switch
                      v-model="isFaceActive"
                      :label="$t('permits.isFaceActive')"
                      color="primary"
                      density="comfortable"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row v-for="token in tokens" :key="`token-${token.loopId}`">
                  <v-col>
                    <Token
                      :ref="`token-${token.loopId}`"
                      :data="token"
                      :objectId="objectId"
                      :allTokens="tokens"
                      :curObject="curObject"
                      @remove="onRemoveToken"
                      @changeToken="onChangeToken"
                      @openQrCodeDialog="openQrCodeDialog"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn color="primary" @click="addPass">
                      <v-icon start> mdi-plus </v-icon>
                      {{ $t("permits.addPass") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="isShowTokens">
          <v-col>
            <v-expansion-panels v-model="activeAdditionalSettingsComputed" flat>
              <v-expansion-panel value="extraPanel">
                <v-expansion-panel-title>
                  <v-label class="text-subtitle-1 font-weight-bold">
                    {{ $t("permits.extraSettings") }}
                  </v-label>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-row>
                    <v-col>
                      <v-switch
                        color="primary"
                        v-model="isNotUse2FA"
                        hide-details
                        density="comfortable"
                        :label="$t('permits.isNotUse2FA')"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="useDateRangeComputed"
                        :label="this.$t('permits.durationTime')"
                        density="comfortable"
                        color="primary"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="useDateRangeComputed">
                    <v-col>
                      <DateRange
                        v-model="dateRange"
                        :settings="{ clearable: true, size: 'large', md: 6 }"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="onepass"
                        :label="$t('permits.limitPasses')"
                        color="primary"
                        density="comfortable"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="onepass">
                    <v-col v-if="isNew">
                      <v-text-field
                        type="number"
                        color="primary"
                        density="compact"
                        v-model.number="pass"
                        :label="$t('permits.passNumber')"
                        variant="outlined"
                        :error-messages="passErrors"
                        @update:modelValue="v$.pass.$touch()"
                        @blur="v$.pass.$touch()"
                      />
                    </v-col>

                    <v-col v-else>
                      <v-row align="center">
                        <v-col cols="12">
                          {{ $t("permits.passNumber") }}: {{ showPass }}
                        </v-col>

                        <v-col>
                          <v-select
                            color="primary"
                            v-model="typePassChange"
                            :items="typePassChangeOptions"
                            item-title="text"
                            item-value="value"
                            density="compact"
                            variant="outlined"
                            hide-details
                          />
                        </v-col>

                        <v-col>
                          <v-text-field
                            type="number"
                            v-model.number="passChange"
                            density="compact"
                            variant="outlined"
                            color="primary"
                            item-title="text"
                            item-value="value"
                            :error-messages="passChangeErrors"
                            hide-details="auto"
                            @input="v$.passChange.$touch()"
                            @blur="v$.passChange.$touch()"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-divider class="mt-5" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="red" text @click="cancel">
          <v-icon start>mdi-close</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>

        <v-btn color="green" outlined @click="onSave">
          <v-icon start>mdi-content-save-outline</v-icon>
          {{ $t("permits.savePermit") }}
        </v-btn>
      </v-card-actions>
    </v-form>

    <!-- Show mode -->
    <template v-else>
      <v-card-text>
        <v-row>
          <v-spacer />

          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-col cols="auto" v-bind="props">
                <v-switch
                  v-model="isActive"
                  hide-details
                  class="mt-0 pt-0"
                  @update:modelValue="onChangeActive"
                />
              </v-col>
            </template>
            <span v-if="!isActive">{{ $t("permits.permitOn") }}</span>
            <span v-else>{{ $t("permits.permitOff") }}</span>
          </v-tooltip>
        </v-row>

        <v-row>
          <v-col cols="6">
            <strong>{{ $t("permits.id") }}</strong>
          </v-col>
          <v-col cols="6">
            {{ id }}
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <strong>{{ $t("permits.passage") }}</strong>
          </v-col>
          <v-col v-if="onepass">
            {{ $t("permits.onePass") }} ({{ $t("permits.passNumber") }}:
            {{ showPass }})
          </v-col>
          <v-col v-else>
            {{ $t("permits.multiPass") }}
          </v-col>
        </v-row>

        <v-row v-if="startDate">
          <v-col>
            <strong>{{ $t("permits.startDate") }}</strong>
          </v-col>
          <v-col>{{ $moment(startDate).format("DD.MM.YYYY HH:mm") }}</v-col>
        </v-row>

        <v-row v-if="expireDate">
          <v-col>
            <strong>{{ $t("permits.expireDate") }}</strong>
          </v-col>
          <v-col>{{ $moment(expireDate).format("DD.MM.YYYY HH:mm") }}</v-col>
        </v-row>

        <v-row v-if="permitAccess.length">
          <v-col>
            <strong>{{ $t("permits.accessLevel") }}</strong>
          </v-col>
          <v-col>{{ accessLevelNames }}</v-col>
        </v-row>

        <v-row>
          <v-col>
            <strong>{{ $t("permits.isNotUse2FA") }}</strong>
          </v-col>
          <v-col>
            {{ isNotUse2FA ? $t("common.yes") : $t("common.no") }}
          </v-col>
        </v-row>

        <template v-if="tokens.length || isFaceActive">
          <h3 class="mt-5 mb-3">{{ $t("permits.pass") }}</h3>

          <v-card v-if="isFaceActive" class="mx-auto mb-3" variant="outlined">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-row dense>
                    <v-col>
                      <strong>{{ $t("permits.type") }}</strong>
                    </v-col>
                    <v-col>
                      {{ $t(`permits.isFaceActive`) }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            v-for="(token, index) in tokens"
            :key="`token-${index}`"
            class="mx-auto mb-3"
            variant="outlined"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <strong>{{ $t("permits.type") }}</strong>
                    </v-col>
                    <v-col>
                      {{ $t(`permits.${token.type}`) }}
                    </v-col>
                  </v-row>
                  <v-row v-if="token.number">
                    <template v-if="token.type == 'accompany'">
                      <v-col>
                        <strong>{{ $t("permits.accompanying") }}</strong>
                      </v-col>
                      <v-col>
                        {{ token.meta?.name }}
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col>
                        <strong>{{ $t("permits.number") }}</strong>
                      </v-col>
                      <v-col>
                        {{ token.number }}
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions v-if="token.type === 'qr'">
              <v-spacer />

              <v-btn
                variant="text"
                size="small"
                @click="openQrCodeDialog(token.number)"
              >
                <v-icon start size="small">mdi-qrcode</v-icon>
                {{ $t("permits.view-qr") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-card-text>

      <v-card-actions v-if="isActive">
        <v-spacer />
        <v-btn variant="text" @click="onEdit">{{
          $t("permits.editPermit")
        }}</v-btn>
      </v-card-actions>
    </template>

    <v-dialog v-model="isOpenQrCodeDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("permits.qr") }}
        </v-card-title>

        <v-card-text id="printQrBlock">
          <div>
            <v-row>
              <v-col class="pa-0">
                <QRCodeVue3
                  width="376"
                  height="376"
                  :value="dialogQrCode"
                  :qrOptions="{
                    typeNumber: '0',
                    mode: 'Byte',
                    errorCorrectionLevel: 'Q',
                  }"
                  :dotsOptions="{ type: 'square', color: '#000000' }"
                  :backgroundOptions="{ color: '#ffffff' }"
                  :cornersSquareOptions="{ type: '', color: '#000000' }"
                  :cornersDotOptions="{ type: '', color: '#000000' }"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <strong>{{ $t("permits.passage") }}</strong>
              </v-col>
              <v-col v-if="onepass">
                {{ $t("permits.onePass") }} ({{ $t("permits.passNumber") }}:
                {{ showPass }})
              </v-col>
              <v-col v-else>
                {{ $t("permits.multiPass") }}
              </v-col>
            </v-row>

            <v-row v-if="startDate">
              <v-col>
                <strong>{{ $t("permits.startDate") }}</strong>
              </v-col>
              <v-col>{{ $moment(startDate).format("DD.MM.YYYY HH:mm") }}</v-col>
            </v-row>

            <v-row v-if="expireDate">
              <v-col>
                <strong>{{ $t("permits.expireDate") }}</strong>
              </v-col>
              <v-col>{{
                $moment(expireDate).format("DD.MM.YYYY HH:mm")
              }}</v-col>
            </v-row>

            <v-row v-if="permitAccess.length">
              <v-col>
                <strong>{{ $t("permits.accessLevel") }}</strong>
              </v-col>
              <v-col>{{ accessLevelNames }}</v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="red" variant="text" @click="isOpenQrCodeDialog = false">
            <v-icon start>mdi-close</v-icon>
            {{ $t("button.close") }}
          </v-btn>

          <v-btn color="success" variant="text" v-print="'#printQrBlock'">
            <v-icon start>mdi-printer</v-icon>
            {{ $t("button.print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
const DATE_FORMAT = "DD.MM.YYYY HH:mm";

import { mapState } from "vuex";

import Token from "./Token";
import AccessModes from "./AccessModes";
import DateRange from "@/components/DateRange";

import validator from "./validator";
import walkTree from "@/mixins/walkTree";
import datesRange from "@/mixins/datesRange.js";

export default {
  name: "PermitCard",

  emits: ["cancel", "save", "changeEdit"],

  mixins: [validator, walkTree, datesRange],

  components: { Token, DateRange, AccessModes },

  props: {
    data: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    objectId: {
      type: [Number, String],
      default: null,
    },
    isShowTokens: {
      type: Boolean,
      default: true,
    },
    curObject: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    const data = this.getData(this.data);

    return {
      ...data,
      activeAdditionalSettings: undefined,
      activeDateRange: undefined,
      previewsData: { ...data },

      isOpenQrCodeDialog: false,
      dialogQrCode: undefined,

      typePassChange: "increment",
      passChange: 0,
      typePassChangeOptions: [
        { value: "increment", text: this.$t("permits.increment") },
        { value: "decrement", text: this.$t("permits.decrement") },
      ],
    };
  },
  watch: {
    data(data) {
      const newData = this.getData(data);

      for (const property in newData) {
        this[property] = newData[property];
      }
    },
  },

  methods: {
    getData(data) {
      let permitAccess = [];
      let tokens = [];
      let values = [];
      let permitAccessObjects = [];

      if (data.permit_access) {
        permitAccess = data.permit_access.map(
          (accessLevel) => accessLevel.accesslevels_id
        );
      }

      if (data.permit_access) {
        permitAccessObjects = data.permit_access.map((permitAccessItem) => {
          const curObject = {
            id: permitAccessItem.accesslevels_id,
            name: permitAccessItem.name,
            access_modes: permitAccessItem.access_modes,
          };

          return curObject;
        });
      }

      if (this.data.tokens && this.data.tokens.length) {
        tokens = this.data.tokens.map((item) => ({
          ...item,
          loopId: item.id ?? item.loopId,
        }));
      }

      if (this.data.values && this.data.values.length) {
        values = [...this.data.values];
      }

      return {
        id: data.id || 0,
        loopId: data.loopId,
        isHide: data.isHide,
        isEdit: data.isEdit,
        isNew: data.isNew || false,
        isActive: data.is_active,
        type: data.type || "employee",
        passChange: data.passChange || 0,
        onepass: data.onepass !== undefined ? data.onepass : false,
        isNotUse2FA:
          data.use_personal_mode !== undefined ? data.use_personal_mode : false,
        pass: Number.isInteger(parseInt(data.pass)) ? data.pass : 1,
        startDate: data.start_time ? data.start_time : null,
        expireDate: data.expire_time ? data.expire_time : null,
        isFaceActive:
          data.is_face_active !== undefined ? data.is_face_active : true,
        permitAccess: [...permitAccess],
        permitAccessObjects: [...permitAccessObjects],
        tokens: [...tokens],
        values: [...values],
      };
    },

    onChangeActive(event) {
      if (event && this.onepass && !this.pass && !this.passChange) {
        this.passChange = 1;
        this.typePassChange = "increment";
      }

      if (!event && this.onepass && !this.pass && this.passChange) {
        this.passChange = 0;
      }
      this.save(event);
    },

    addPass() {
      let loopId = 1;
      if (this.tokens.length > 0) {
        const lastID =
          this.tokens[this.tokens.length - 1].id ||
          this.tokens[this.tokens.length - 1].loopId;

        loopId = lastID + 1;
      }

      this.tokens.push({
        loopId,
        type: "card",
        number: "",
      });
    },

    onRemoveToken(id) {
      this.tokens = this.tokens.filter((token) => token.loopId !== id);
    },

    onChangeToken({ loopId, type, number, name, meta }) {
      this.tokens = this.tokens.map((token) => {
        const newToken = { ...token };

        if (token.loopId === loopId) {
          newToken.type = type;
          newToken.name = name;
          newToken.number = number;
          newToken.meta = meta;
        }

        return newToken;
      });
    },

    onChangeField(value) {
      const index = this.values.findIndex((p) => p.key === value.key);

      if (index !== -1) {
        this.values.splice(index, 1, value);
      } else {
        this.values.push(value);
      }
    },

    cancel() {
      if (this.id) {
        this.save(this.previewsData);
      } else {
        this.$emit("cancel", this.loopId);
      }
    },

    onSave() {
      this.v$
        .$validate()
        .then((result) => {
          if (result) this.save(this);
        })
        .catch(() => {
          return;
        });
    },

    save(permit) {
      if (typeof permit == "boolean") permit = this;
      const data = {
        loopId: permit.loopId,
        id: permit.id,
        isEdit: false,
        isHide: permit.isHide,
        is_active: permit.isActive,
        type: permit.type,
        onepass: permit.onepass,
        use_personal_mode: permit.isNotUse2FA,
        start_time: permit.startDate,
        expire_time: permit.expireDate,
        permit_access: permit.permitAccessObjects.map((accessLevel) => ({
          accesslevels_id: accessLevel.id,
          name: accessLevel.name,
          access_modes: accessLevel.access_modes,
        })),
        passChange: permit.passChange,
        pass: permit.pass,
        is_face_active: permit.isFaceActive,
        tokens: permit.tokens,
      };

      if (permit.id) {
        data.id = permit.id;
      }

      if (permit.onepass && permit.isNew) {
        data.pass = permit.pass;
      }

      if (permit.passChange) {
        data.pass_change = {
          value: permit.passChange,
          is_increment: permit.typePassChange === "increment",
        };
      }

      this.$emit("save", data);
    },

    onEdit() {
      this.isEdit = true;
      this.$emit("changeEdit", { id: this.id, isEdit: true });
    },

    openQrCodeDialog(qr) {
      this.dialogQrCode = qr;
      this.isOpenQrCodeDialog = true;
    },

    closeQrCodeDialog() {
      this.dialogQrCode = undefined;
      this.isOpenQrCodeDialog = false;
    },
  },

  computed: {
    ...mapState({
      accessLevels: (state) => state.accessLevels.tree,
    }),

    showPass() {
      let showPass = 0;
      switch (this.typePassChange) {
        case "increment":
          showPass = this.passChange
            ? `${this.pass} + ${this.passChange} (${
                this.pass + this.passChange
              })`
            : this.pass;
          break;
        case "decrement":
          if (this.pass >= this.passChange) {
            showPass = this.passChange
              ? `${this.pass} - ${this.passChange} (${
                  this.pass - this.passChange
                })`
              : this.pass;
          } else {
            showPass = `${this.pass} - ${this.passChange} (0)`;
          }
          break;
        default:
          break;
      }
      return showPass;
    },
    accessLevelNames() {
      const list = [];
      this.accessLevels.forEach((element) => {
        this.walk(element, (el) => {
          if (this.permitAccess.includes(el.id)) list.push(el);
        });
      });

      return list.map((item) => item.name).join(", ");
    },

    cardColor() {
      let color = "#F3F2FF";

      if (!this.isActive) {
        color = "#EEEEEE";
      }

      return color;
    },

    activeAdditionalSettingsComputed: {
      get() {
        return this.activeAdditionalSettings ??
          (this.isNotUse2FA || this.useDateRangeComputed || this.onepass)
          ? "extraPanel"
          : "";
      },
      set(newValue) {
        this.activeAdditionalSettings = newValue ?? "";
      },
    },

    useDateRangeComputed: {
      get() {
        return this.activeDateRange ?? (!!this.startDate || !!this.expireDate);
      },
      set(newValue) {
        this.activeDateRange = newValue;

        if (!newValue) {
          this.startDate = undefined;
          this.expireDate = undefined;
        }
      },
    },

    dateRange: {
      get() {
        return [this.startDate, this.expireDate];
      },
      set(newValue) {
        this.startDate = newValue[0];
        this.expireDate = newValue[1];
      },
    },
  },
};
</script>
