export default {
  setLoadingGetObjectTypes(state, payload) {
    state.isPendingGetObjectTypes = payload.type === "IS_PENDING";
    state.isSuccessGetObjectTypes = payload.type === "IS_SUCCESS";
    state.isFailureGetObjectTypes = payload.type === "IS_FAILURE";
    state.errorMessageGetObjectTypes = payload.payload;
  },

  setObjectTypes(state, payload) {
    state.objectTypes = payload;
  },

  setLoadingGetObject(state, { type, payload = "" }) {
    state.isPendingGetObject = type === "IS_PENDING";
    state.isSuccessGetObject = type === "IS_SUCCESS";
    state.isFailureGetObject = type === "IS_FAILURE";
    state.errorMessageGetObject = payload;
  },

  setObject(state, payload) {
    state.object = payload;
  },

  setLoadingAddObject(state, { type, payload = "" }) {
    state.isPendingAddObject = type === "IS_PENDING";
    state.isSuccessAddObject = type === "IS_SUCCESS";
    state.isFailureAddObject = type === "IS_FAILURE";
    state.errorMessageAddObject = payload;
  },

  setLoadingUpdateObject(state, { type, payload = "" }) {
    state.isPendingUpdateObject = type === "IS_PENDING";
    state.isSuccessUpdateObject = type === "IS_SUCCESS";
    state.isFailureUpdateObject = type === "IS_FAILURE";
    state.errorMessageUpdateObject = payload;
  },

  setLoadingGetObjectsList(state, { type, payload = "" }) {
    state.isPendingGetObjectsList = type === "IS_PENDING";
    state.isSuccessGetObjectsList = type === "IS_SUCCESS";
    state.isFailureGetObjectsList = type === "IS_FAILURE";
    state.errorMessageGetObjectsList = payload;
  },

  setObjectsList(state, payload) {
    state.objectsList = payload;
  },

  setLoadingDeleteObject(state, { type, payload = "" }) {
    state.isPendingDeleteObject = type === "IS_PENDING";
    state.isSuccessDeleteObject = type === "IS_SUCCESS";
    state.isFailureDeleteObject = type === "IS_FAILURE";
    state.errorMessageDeleteObject = payload;
  },

  setObjectHistory(state, payload) {
    state.objectHistory = payload;
  },

  setLoadingGetObjectHistory(state, { type, payload = "" }) {
    state.isPendingGetObjectHistory = type === "IS_PENDING";
    state.isSuccessGetObjectHistory = type === "IS_SUCCESS";
    state.isFailureGetObjectHistory = type === "IS_FAILURE";
    state.errorMessageGetObjectHistory = payload;
  },
};
