import { createRouter, createWebHistory } from "vue-router";

// Constants rules
import RULES from "@/constants/rules";
import ROUTES from "@/constants/routes";

// PAGES
// Monitoring
import Monitoring from "./pages/monitoring/index.vue";
import FullScreenMonitoring from "./pages/monitoring/FullScreen.vue";
import VisitorTable from "./pages/visitor-table/index.vue";
import IndoorTable from "./pages/indoor-table/index.vue";
import EventLogTable from "./pages/event-log-table/index.vue";

// Analytics
import Analytics from "./pages/analytics";

// Events journal
import EventsJournal from "./pages/events";

// Settings
import Settings from "./pages/settings";
import GeneralSettings from "./pages/general-settings";
import ImportPage from "./pages/import-export";
import Notifications from "./pages/notifications";
import Log from "./pages/log";

// Personal
import EditPersonal from "./pages/personal/Edit";

// newObject
import ObjectListPage from "./pages/object/List";
import ObjectShowPage from "./pages/object/Show";
import ObjectDataPage from "./pages/object/Show/Object.vue";
import ObjectStatisticsPage from "./pages/object/Show/Statistics.vue";
import ObjectHistoryPage from "./pages/object/Show/History.vue";
import ObjectEdit from "./pages/object/Edit";
import ObjectAdd from "./pages/object/Add";

// ObjectTypes
import ObjectTypeList from "./pages/object-types/index.vue";
import ObjectTypeAdd from "./pages/object-types/add.vue";
import ObjectTypeEdit from "./pages/object-types/edit.vue";

// ProfileTypes
import ProfileTypeList from "./pages/profile-types/index.vue";
import ProfileTypeAdd from "./pages/profile-types/add.vue";
import ProfileTypeEdit from "./pages/profile-types/edit.vue";

// Zones And Devices
import ZonesAndDevices from "./pages/zones-and-devices";

// Documents
import Documents from "./pages/documents";

// Admission
import Admission from "./pages/admission";

// Profile
import ProfileAdd from "./pages/profile/add";
import ProfileEdit from "./pages/profile/edit";
import ProfileShow from "./pages/profile/Show";
import ProfileData from "./pages/profile/Show/Profile.vue";

// Departments
import Departments from "./pages/departments";
import DepartmentAdd from "./pages/departments/add";
import DepartmentEdit from "./pages/departments/edit";

// Access levels
import AccessLevels from "./pages/access-levels";
import AccessLevelAdd from "./pages/access-levels/add";
import AccessLevelEdit from "./pages/access-levels/edit";

// Permit Requests
import PermitRequests from "./pages/permit-requests";
import AddPermitRequest from "./pages/permit-requests/add";
import AddMultiPermitRequest from "./pages/permit-requests/add-multi";
import EditPermitRequest from "./pages/permit-requests/edit";

// Operators
import Operators from "./pages/operators";
import OperatorAdd from "./pages/operators/add";
import OperatorEdit from "./pages/operators/edit";

//ACcess
import Access from "./pages/access";

// Devices
import DeviceList from "./pages/devices";
import DeviceEdit from "./pages/devices/edit";

// Zones
import Zones from "./pages/zones";
import ZoneAdd from "./pages/zones/add";
import ZoneEdit from "./pages/zones/edit";

//Access Modes
import AccessModes from "./pages/access-modes";
import AccessModesAdd from "./pages/access-modes/add.vue";
import AccessModesEdit from "./pages/access-modes/edit.vue";
// Access denied
import AccessDenied from "./pages/AccessDenied";

// Redirect
import RedirectPage from "./pages/RedirectPage";

// Permits
//import Permits from "./pages/permits";
import PermitsEdit from "./pages/permits/edit";

//Access links
//import AccessLinks from "./pages/access-links";

// 404
import NotFound from "./pages/404";

const routes = [
  //Redirect
  {
    path: "/",
    name: ROUTES.REDIRECT_PAGE,
    component: RedirectPage,
    meta: {
      layout: "blank",
    },
  },
  // Monitoring
  {
    path: "/monitoring",
    name: ROUTES.MONITORING,
    component: Monitoring,
    meta: {
      auth: true,
      permissions: [RULES.MONITORING_VIEW],
      forRedirect: true,
      redirectIndex: 1,
    },
    children: [
      // {
      //   path: "visitor-table",
      //   name: ROUTES.VISITOR_TABLE,
      //   component: VisitorTable,
      //   meta: {
      //     auth: true,
      //     permissions: [RULES.MONITORING_VIEW],
      //   },
      // },
      {
        path: "indoor-table",
        name: ROUTES.INDOOR_TABLE,
        component: IndoorTable,
        meta: {
          auth: true,
          permissions: [RULES.MONITORING_VIEW],
        },
      },
      {
        path: "event-log-table",
        name: ROUTES.EVENT_LOG_TABLE,
        component: EventLogTable,
        meta: {
          auth: true,
          permissions: [RULES.MONITORING_VIEW],
        },
      },
    ],
  },

  {
    path: "/monitoring/fullscreen",
    name: ROUTES.FULLSCREEN_MONITORING,
    component: FullScreenMonitoring,
    meta: {
      auth: true,
      layout: "blank",
      permissions: [RULES.MONITORING_VIEW],
    },
  },

  // Analytics
  {
    path: "/analytics",
    name: ROUTES.ANALYTICS,
    component: Analytics,
    meta: {
      auth: true,
      permissions: [RULES.JOURNAL_VIEW],
      forRedirect: true,
      redirectIndex: 6,
    },

    children: [
      // Passes journal
      // {
      //   path: "passes",
      //   name: ROUTES.PASSES_JOURNAL,
      //   component: PassesJournal,
      // },
      // Events journal
      {
        path: "events",
        name: ROUTES.EVENTS_JOURNAL,
        component: EventsJournal,
      },
    ],
  },

  // Settings
  {
    path: "/settings",
    name: ROUTES.SETTINGS,
    component: Settings,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.SETTINGS_VIEW],
      forRedirect: true,
      redirectIndex: 7,
    },
    children: [
      // General
      {
        path: "general-settings",
        name: ROUTES.GENERAL_SETTINGS,
        component: GeneralSettings,
      },
      // Import
      {
        path: "import",
        name: ROUTES.IMPORT,
        component: ImportPage,
      },
      // Notifications

      {
        path: "notifications",
        name: ROUTES.NOTIFICATIONS,
        component: Notifications,
      },
      // Log

      {
        path: "log",
        name: ROUTES.LOG,
        component: Log,
      },
    ],
  },

  // Personal
  {
    path: "/personal",
    name: ROUTES.EDIT_PERSONAL,
    component: EditPersonal,
    meta: {
      auth: true,
      isNarrowContainer: true,
      forRedirect: true,
      redirectIndex: 8,
    },
  },

  // Admission
  {
    path: "/admission",
    name: ROUTES.ADMISSION,
    component: Admission,
    meta: {
      auth: true,
      permissions: [
        RULES.PERSON_VIEW,
        RULES.OPERATOR_VIEW,
        RULES.DEPARTMENT_VIEW,
        RULES.ACCESS_LEVELS_VIEW,
      ],
      forRedirect: true,
      redirectIndex: 2,
    },

    children: [
      // Persons
      {
        path: "object-list",
        name: ROUTES.OBJECT_LIST,
        component: ObjectListPage,
        meta: {
          auth: true,
          permissions: [RULES.PERSON_VIEW],
        },
      },
      // Departments
      {
        path: "departments",
        name: ROUTES.DEPARTMENTS,
        component: Departments,
        meta: {
          auth: true,
          permissions: [RULES.DEPARTMENT_VIEW],
        },
      },
      // Operators
      {
        path: "operators",
        name: ROUTES.OPERATORS,
        component: Operators,
        meta: {
          auth: true,
          permissions: [RULES.OPERATOR_VIEW],
        },
      },
      //ObjectTypes
      {
        path: "object-type",
        name: ROUTES.OBJECT_TYPE,
        component: ObjectTypeList,
        meta: {
          auth: true,
        },
      },
      //ProfileTypes
      {
        path: "profile-type",
        name: ROUTES.PROFILE_TYPE,
        component: ProfileTypeList,
        meta: {
          auth: true,
        },
      },
    ],
  },

  //Access
  {
    path: "/access",
    name: ROUTES.ACCESS,
    component: Access,
    meta: {
      auth: true,
      forRedirect: true,
      redirectIndex: 3,
    },
    children: [
      // Access Levels
      {
        path: "access-levels",
        name: ROUTES.ACCESS_LEVELS,
        component: AccessLevels,
        meta: {
          auth: true,
          permissions: [RULES.ACCESS_LEVELS_VIEW],
        },
      },
      //Access Modes
      {
        path: "access-modes",
        name: ROUTES.ACCESS_MODES,
        component: AccessModes,
        meta: {
          auth: true,
        },
      },

      //Access Links
      // {
      //   path: "access-links",
      //   name: ROUTES.ACCESS_LINKS,
      //   component: AccessLinks,
      //   meta: {
      //     auth: true,
      //   },
      // },
    ],
  },

  //Object New
  {
    path: "/admission/object/:id",
    name: ROUTES.OBJECT_SHOW,
    component: ObjectShowPage,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.PERSON_VIEW],
    },
    children: [
      {
        path: "object-data",
        name: ROUTES.OBJECT_DATA,
        component: ObjectDataPage,
        meta: {
          auth: true,
          permissions: [RULES.PERSON_VIEW],
        },
      },
      {
        path: "object-statistics",
        name: ROUTES.OBJECT_STATISTICS,
        component: ObjectStatisticsPage,
        meta: {
          auth: true,
          permissions: [RULES.PERSON_VIEW],
        },
      },
      {
        path: "object-history",
        name: ROUTES.OBJECT_HISTORY,
        component: ObjectHistoryPage,
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: "/admission/object/:id/edit",
    name: ROUTES.EDIT_OBJECT,
    component: ObjectEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.PERSON_EDIT],
    },
  },
  {
    path: "/admission/object/add",
    name: ROUTES.ADD_OBJECT,
    component: ObjectAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.PERSON_ADD],
    },
  },

  // Profiles
  {
    path: "/admission/profile/:id",
    name: ROUTES.PROFILE_SHOW,
    component: ProfileShow,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.PERSON_VIEW],
    },
    children: [
      {
        path: "show",
        name: ROUTES.PROFILE_DATA,
        component: ProfileData,
        meta: {
          auth: true,
          isNarrowContainer: true,
          permissions: [RULES.PERSON_VIEW],
        },
      },
    ],
  },
  {
    path: "/admission/profile/:object_id/add",
    name: ROUTES.ADD_PROFILE,
    component: ProfileAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.PERSON_ADD],
    },
  },
  {
    path: "/admission/profile/:id/edit",
    name: ROUTES.EDIT_PROFILE,
    component: ProfileEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.PERSON_EDIT],
    },
  },

  // Object type
  {
    path: "/admission/object-type/add",
    name: ROUTES.ADD_OBJECT_TYPE,
    component: ObjectTypeAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      isLimitWidth: true,
    },
  },
  {
    path: "/admission/object-type/:id",
    name: ROUTES.EDIT_OBJECT_TYPE,
    component: ObjectTypeEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      isLimitWidth: true,
    },
  },

  //Profile type
  {
    path: "/admission/profile-type/add",
    name: ROUTES.ADD_PROFILE_TYPE,
    component: ProfileTypeAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      isLimitWidth: true,
    },
  },
  {
    path: "/admission/profile-type/:id",
    name: ROUTES.EDIT_PROFILE_TYPE,
    component: ProfileTypeEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      isLimitWidth: true,
    },
  },

  // Departments
  {
    path: "/admission/department/add",
    name: ROUTES.ADD_DEPARTMENT,
    component: DepartmentAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.DEPARTMENT_ADD],
    },
  },
  {
    path: "/admission/department/:id/edit",
    name: ROUTES.EDIT_DEPARTMENT,
    component: DepartmentEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.DEPARTMENT_EDIT],
    },
  },

  // Access Levels
  {
    path: "/admission/access-level/add",
    name: ROUTES.ADD_ACCESS_LEVEL,
    component: AccessLevelAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.ACCESS_LEVELS_ADD],
    },
  },
  {
    path: "/admission/access-level/:id/edit",
    name: ROUTES.EDIT_ACCESS_LEVEL,
    component: AccessLevelEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.ACCESS_LEVELS_EDIT],
    },
  },

  // Operators
  {
    path: "/admission/operators/add",
    name: ROUTES.ADD_OPERATOR,
    component: OperatorAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.OPERATOR_ADD],
    },
  },
  {
    path: "/admission/operators/:id/edit",
    name: ROUTES.EDIT_OPERATOR,
    component: OperatorEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.OPERATOR_EDIT],
    },
  },

  // Access Modes
  {
    path: "/admission/access-modes/add",
    name: ROUTES.ADD_ACCESS_MODES,
    component: AccessModesAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
    },
  },
  {
    path: "/admission/access-modes/:id/edit",
    name: ROUTES.EDIT_ACCESS_MODES,
    component: AccessModesEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
    },
  },

  //Permits
  {
    path: "/admission/permits/:id/edit",
    name: ROUTES.EDIT_PERMIT,
    component: PermitsEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
    },
  },

  // Zones and Devices
  {
    path: "/zones-and-devices",
    name: ROUTES.ZONES_AND_DEVICES,
    component: ZonesAndDevices,
    meta: {
      auth: true,
      permissions: [RULES.DEVICE_VIEW, RULES.ZONE_VIEW],
      forRedirect: true,
      redirectIndex: 4,
    },

    children: [
      // Devices
      {
        path: "devices",
        name: ROUTES.DEVICES,
        component: DeviceList,
        meta: {
          auth: true,
          permissions: [RULES.DEVICE_VIEW],
        },
      },

      // Zones
      {
        path: "zones",
        name: ROUTES.ZONES,
        component: Zones,
        meta: {
          auth: true,
          permissions: [RULES.ZONE_VIEW],
        },
      },
    ],
  },

  // Devices
  {
    path: "/object/devices/add",
    name: ROUTES.ADD_DEVICE,
    component: DeviceEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      isLimitWidth: true,
      permissions: [RULES.DEVICE_ADD],
    },
  },
  {
    path: "/object/devices/:id/edit",
    name: ROUTES.EDIT_DEVICE,
    component: DeviceEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      isLimitWidth: true,
      permissions: [RULES.DEVICE_EDIT],
    },
  },

  // Zones
  {
    path: "/object/zones/add",
    name: ROUTES.ADD_ZONE,
    component: ZoneAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.ZONE_ADD],
    },
  },
  {
    path: "/object/zones/:id/edit",
    name: ROUTES.EDIT_ZONE,
    component: ZoneEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.ZONE_EDIT],
    },
  },

  // Documents
  // {
  //   path: "/documents",
  //   name: ROUTES.DOCUMENTS,
  //   component: Documents,
  //   meta: {
  //     auth: true,
  //     permissions: [RULES.REQUEST_VIEW],
  //     forRedirect: true,
  //     redirectIndex: 5,
  //   },

  //   children: [
  //     // Permit Requests
  //     {
  //       path: "permit-requests",
  //       name: ROUTES.PERMIT_REQUESTS,
  //       component: PermitRequests,
  //       meta: {
  //         auth: true,
  //         permissions: [RULES.REQUEST_VIEW],
  //       },
  //     },
  //   ],
  // },

  // Permit Requests
  {
    path: "/documents/permit-requests/add",
    name: ROUTES.ADD_PERMIT_REQUEST,
    component: AddPermitRequest,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.REQUEST_ADD],
    },
  },
  {
    path: "/documents/permit-requests/add-multi",
    name: ROUTES.ADD_MULTI_PERMIT_REQUEST,
    component: AddMultiPermitRequest,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.REQUEST_ADD],
    },
  },
  {
    path: "/documents/permit-requests/:id",
    name: ROUTES.EDIT_PERMIT_REQUEST,
    component: EditPermitRequest,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.REQUEST_VIEW],
    },
  },

  //Access Denied
  {
    path: "/access-denied",
    name: ROUTES.ACCESS_DENIED,
    component: AccessDenied,
    meta: {
      layout: "blank",
    },
  },

  // Not found
  {
    path: "/404",
    name: "notFound",
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  routes,
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  history: createWebHistory(),
});

export default router;
