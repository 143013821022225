import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  isPendingGetObjectTypes: false,
  isSuccessGetObjectTypes: false,
  isFailureGetObjectTypes: false,
  errorMessageGetObjectTypes: "",

  objectTypes: [],

  isPendingGetObject: false,
  isSuccessGetObject: false,
  isFailureGetObject: false,
  errorMessageGetObject: "",

  object: {},

  isPendingAddObject: false,
  isSuccessAddObject: false,
  isFailureAddObject: false,
  errorMessageAddObject: "",

  isPendingUpdateObject: false,
  isSuccessUpdateObject: false,
  isFailureUpdateObject: false,
  errorMessageUpdateObject: "",

  isPendingGetObjectsList: false,
  isSuccessGetObjectsList: false,
  isFailureGetObjectsList: false,
  errorMessageGetObjectsList: "",

  objectsList: [],

  isPendingDeleteObject: false,
  isSuccessDeleteObject: false,
  isFailureDeleteObject: false,
  errorMessageDeleteObject: "",

  isPendingGetObjectHistory: false,
  isSuccessGetObjectHistory: false,
  isFailureGetObjectHistory: false,
  errorMessageGetObjectHistory: "",

  objectHistory: {},
};

export default {
  state,
  mutations,
  actions,
  getters,
};
