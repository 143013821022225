<template>
  <v-card class="mx-auto" outlined color="#F3F2FF">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="type"
            :items="templates"
            :label="$t('operators.typeRole')"
            item-title="name"
            item-value="type"
            variant="outlined"
            density="comfortable"
            color="primary"
            @update:modelValue="onChangeType"
          />
        </v-col>

        <v-col cols="12" md="4">
          <label>{{ departmentsLabel }}</label>
          <el-tree-select
            v-model="departments"
            node-key="id"
            :data="departmentsList"
            check-strictly
            :render-after-expand="false"
            show-checkbox
            check-on-click-node
            :props="{ label: 'name' }"
            :placeholder="$t('operators.all')"
            filterable
            multiple
            @update:modelValue="onChange"
          />
        </v-col>

        <v-col cols="12" md="4">
          <label>{{ accessLevelLabel }}</label>
          <el-tree-select
            v-model="accessLevels"
            node-key="id"
            :data="accessLevelsList"
            check-strictly
            :render-after-expand="false"
            show-checkbox
            check-on-click-node
            :props="{ label: 'name' }"
            :placeholder="$t('operators.all')"
            filterable
            multiple
            @update:modelValue="onChange"
          />
        </v-col>

        <v-col cols="12" md="4">
          <label>{{ zonesLabel }}</label>
          <el-tree-select
            v-model="zones"
            node-key="id"
            :data="zonesList"
            check-strictly
            :render-after-expand="false"
            show-checkbox
            check-on-click-node
            :props="{ label: 'name' }"
            :placeholder="$t('operators.all')"
            filterable
            multiple
            @update:modelValue="onChange"
          />
        </v-col>

        <v-col cols="12">
          <v-card class="mb-4" elevation="0">
            <v-toolbar
              :color="this.v$.rules.$invalid ? 'red' : 'primary'"
              dark
              flat
            >
              <v-toolbar-title>{{ $t("operators.rules") }}</v-toolbar-title>
            </v-toolbar>

            <v-row>
              <v-col cols="12" md="6">
                <v-card-text>
                  <el-tree
                    ref="rulesTree"
                    :data="items"
                    node-key="id"
                    :props="{
                      label: 'name',
                      class: addClass,
                      disabled: isDisabled,
                    }"
                    default-expand-all
                    check-on-click-node
                    show-checkbox
                    :check-strictly="true"
                    :default-checked-keys="rules"
                    @check-change="onChange"
                  >
                    <template #="{ data }">
                      {{ data.name }}
                      <v-tooltip
                        v-if="data.info"
                        location="right"
                        max-width="250"
                      >
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props" color="grey" size="16"
                            >mdi-information</v-icon
                          >
                        </template>
                        <span>{{ data.info }}</span>
                      </v-tooltip>
                    </template>
                  </el-tree>
                </v-card-text>
              </v-col>

              <v-divider vertical />

              <v-col cols="12" md="6">
                <v-card-text>
                  <div
                    v-if="rules.length === 0"
                    key="title"
                    class="text-h6 font-weight-light grey--text pa-4 text-center"
                  >
                    {{ $t("operators.chooseRules") }}
                  </div>

                  <v-scroll-x-transition group hide-on-leave>
                    <div v-for="rule in items" :key="rule.id">
                      <div v-if="getRulesByRegex(rule.id).length" class="mb-5">
                        <h2 class="mb-3">{{ rule.name }}</h2>
                        <v-chip
                          v-for="selection in getRulesByRegex(rule.id)"
                          :key="selection"
                          color="info"
                          dark
                          small
                          closable
                          outlined
                          @click:close="removeRuleByID(selection)"
                          class="ma-1"
                        >
                          {{ $t(`rules.full.${selection}`) }}
                        </v-chip>
                      </div>
                    </div>
                  </v-scroll-x-transition>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-if="isDelete">
      <v-spacer />
      <v-btn color="red" text @click="onCancel">
        {{ $t("button.delete") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

import validator from "./validator";

export default {
  name: "OperatorRole",

  mixins: [validator],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    templates: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      type: "",
      departments: [],
      accessLevels: [],
      zones: [],
      rules: [],

      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
        };
      },
    };
  },

  watch: {
    data() {
      this.getData();
    },
  },

  computed: {
    ...mapState({
      departmentsList: (state) => state.departments.tree,
      accessLevelsList: (state) => state.accessLevels.tree,
      zonesList: (state) => state.zones.list,
    }),

    isAccessLevelsRequired() {
      let isRequired = false;

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        isRequired = roleArr[0].req_accesslevels;
      }

      return isRequired;
    },

    isDepartmentsRequired() {
      let isRequired = false;

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        isRequired = roleArr[0].req_departments;
      }

      return isRequired;
    },

    isZonesRequired() {
      let isRequired = false;

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        isRequired = roleArr[0].req_zones;
      }

      return isRequired;
    },

    accessLevelLabel() {
      let label = this.$t("operators.accessLevel");

      if (this.isAccessLevelsRequired) {
        label += " *";
      }

      return label;
    },

    zonesLabel() {
      let label = this.$t("operators.zone");

      if (this.isZonesRequired) {
        label += " *";
      }

      return label;
    },

    departmentsLabel() {
      let label = this.$t("operators.department");

      if (this.isDepartmentsRequired) {
        label += " *";
      }

      return label;
    },
  },

  methods: {
    onChange() {
      this.v$.rules.$touch();
      this.$emit("updateData", {
        loopId: this.data.loopId,
        type: this.type,
        departments: this.departments,
        accesslevels: this.accessLevels,
        allowed_zones: this.zones,
        permissions: this.$refs.rulesTree.getCheckedKeys(),
      });
    },
    onChangeType() {
      this.rules = [...this.getDefaultPermissions()];
      this.$refs.rulesTree.setCheckedNodes(this.rules);
    },

    getRulesByRegex(regex) {
      return this.rules.filter((item) => item.match(regex));
    },

    isDisabled(data) {
      if (data?.children?.length) return true;
      return false;
    },
    addClass(data) {
      if (data?.children?.length) return "el-tree-node--hide-checkbox";
      return "";
    },

    removeRuleByID(ruleID) {
      this.$refs.rulesTree.setChecked(ruleID, false, false);
      this.onChange();
    },

    onCancel() {
      this.$emit("cancel", this.data.loopId);
    },

    getDefaultPermissions(type) {
      let permissions = [];

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        permissions = roleArr[0].permissions;
      }

      return permissions;
    },
    toDefault() {
      this.rules = this.rules.length
        ? this.rules
        : [...this.getDefaultPermissions()];
    },
    getData() {
      this.type = this.data?.type || this.$const.ROLES.OPERATOR;
      this.departments = this.data?.departments || [];
      this.accessLevels = this.data?.accesslevels || [];
      this.zones = this.data?.allowed_zones || [];
      this.rules = this.data?.permissions || [];
    },
  },

  created() {
    this.getData();
    this.toDefault();
  },

  mounted() {
    this.onChange();
  },
};
</script>
<style>
.el-tree-node.el-tree-node--hide-checkbox
  > .el-tree-node__content
  .el-checkbox {
  display: none;
}
</style>
