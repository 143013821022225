export default {
  data() {
    return {
      isOpenDepartmentDialog: false,
      isOpenAccessLevelsDialog: false,
    };
  },

  methods: {
    async delete({ ids, filter }) {
      if (ids?.length) {
        const params = { ids };

        await this.deleteObjectAction(params);
      } else {
        const params = { ...filter };

        await this.deleteObjectAction(params);
      }

      this.getObjects();
      this.selected = [];
    },

    async onBlock({ ids, filter }) {
      if (ids?.length) {
        await this.block({ ids });
      } else {
        await this.block({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },

    async onBlack({ ids, filter }) {
      if (ids?.length) {
        await this.black({ ids });
      } else {
        await this.black({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },

    async onUnblock({ ids, filter }) {
      if (ids?.length) {
        await this.unblock({ ids });
      } else {
        await this.unblock({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },

    async onUnblack({ ids, filter }) {
      if (ids?.length) {
        await this.unblack({ ids });
      } else {
        await this.unblack({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },

    async onRestore({ ids, filter }) {
      if (ids && ids.length) {
        await this.restore({ ids });
      } else {
        await this.restore({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },

    onRemove(data) {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),
        agree: () => this.delete(data),
      });
    },

    onMultiSetDepartment() {
      this.getObjects();
      this.selected = [];
      this.isOpenDepartmentDialog = false;
    },

    onMultiSetAccessLevel() {
      this.getObjects();
      this.selected = [];
      this.isOpenAccessLevelsDialog = false;
    },
  },

  computed: {
    isDeleteObject() {
      return this.can(this.$const.RULES.PERSON_DELETE);
    },

    isRestoreObject() {
      return this.can(this.$const.RULES.PERSON_RESTORE);
    },
  },
};
