<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="events"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col cols="12" md="9">
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="auto">
          <v-menu offset-y>
            <template #activator="{ props }">
              <v-btn color="primary" v-bind="props">
                {{ $t("advanced.allEvents") }}&nbsp;
                <v-icon end> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list density="comfortable">
              <v-list-item @click="getEventsReport('report/worktime')">
                <v-list-item-title>{{
                  $t("advanced.worktime")
                }}</v-list-item-title>
                <template #append>
                  <v-tooltip location="left" max-width="250">
                    <template #activator="{ props }">
                      <v-icon v-bind="props" color="grey-lighten-1"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>{{ $t("advanced.onlyZoneWithWorktime") }}</span>
                  </v-tooltip>
                </template>
              </v-list-item>
              <v-list-item @click="getEventsReport('report/iotime')">
                <v-list-item-title>{{
                  $t("advanced.iotime")
                }}</v-list-item-title>
                <template #append>
                  <v-tooltip location="left" max-width="250">
                    <template #activator="{ props }">
                      <v-icon v-bind="props" color="grey-lighten-1"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>{{ $t("advanced.onlyZoneWithWorktime") }}</span>
                  </v-tooltip>
                </template>
              </v-list-item>
              <v-list-item @click="getEventsReport('report/passed')">
                <v-list-item-title>{{
                  $t("advanced.passed")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="events"
            disable-sort
            :items-per-page="pagination.limit"
            :row-props="
              (row) => {
                return { class: indicator(row.item) };
              }
            "
            item-key="id"
          >
            <template #[`item.avatar`]="{ item }">
              <EventAvatar :item="item" />
            </template>

            <template #[`item.identity_types`]="{ item }">
              <EventPurposeType :item="item" />
              <EventIdentityTypes :item="item" />
            </template>

            <template #[`item.pass_type`]="{ item }">
              <EventPassType :item="item" />
              <EventActions :item="item" />
            </template>

            <template #[`item.content`]="{ item }">
              <EventContent :item="item" />
              <EventContentChips :item="item" />
            </template>

            <template #[`item.formatCreatedAt`]="{ item }">
              <EventContent
                :item="item"
                :name="{
                  show: false,
                }"
                :purpose="{ show: false }"
                :temperature="{ show: false }"
                :formatCreatedAt="{
                  show: true,
                }"
              />
            </template>
            <template #bottom>
              <DataTableFooterV2
                v-model="pagination"
                @update:modelValue="onPagination"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";

import {
  EventPurposeType,
  EventIdentityTypes,
  EventAvatar,
  EventContent,
  EventContentChips,
  EventPassType,
  EventActions,
  EventIndicator,
} from "@/components/EventsTools";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";

import correctVisit from "@/mixins/correctVisit";

export default {
  components: {
    DataTableFooterV2,
    EventAvatar,
    EventIdentityTypes,
    EventPurposeType,
    EventContent,
    EventContentChips,
    EventPassType,
    EventActions,
    Filter,
  },

  mixins: [correctVisit, EventIndicator],

  data() {
    return {
      id: null,

      events: [],

      headers: [
        {
          value: "pass_type",
          width: 8,
          cellProps: {
            class: "pl-2 pr-0 py-2",
          },
        },
        {
          value: "identity_types",
          width: 8,
          cellProps: {
            class: "pl-1 pr-0 py-2",
          },
        },
        {
          value: "avatar",
          width: 50,
          cellProps: {
            class: "py-2",
          },
        },
        {
          value: "content",
          title: this.$t("users.visitor").toUpperCase(),
          cellProps: {
            class: "py-2",
          },
        },
        {
          value: "formatCreatedAt",
          title: this.$t("users.visit_time").toUpperCase(),
          width: 50,
          align: "center",
        },
      ],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      getReportAction: "passes/report",

      getEventLogsAction: "visits/getEventLogs",

      setStatePageDataAction: "stateObjectStatistics/setPageData",
      setStateFilterAction: "stateObjectStatistics/setFilter",
      setStatePaginationAction: "stateObjectStatistics/setPagination",
    }),

    getId() {
      this.id = parseInt(this.$route.params.id);
    },

    getEvents() {
      const params = {
        ...this.filter,
        ...this.pagination,
        objectId: this.id,
      };

      this.getEventLogsAction(params).then((response) => {
        this.events = this.eventsState.map((event) => this.correctVisit(event));

        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;

          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }
      });
    },

    getEventsReport(url) {
      const params = {
        ...this.filter,
        ...this.pagination,
        objectId: this.id,
      };

      this.getReportAction({ params, url });
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния таблицы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },

    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };

      this.setStatePaginationAction(this.pagination);
    },
    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getEvents();
    },
  },

  computed: {
    ...mapState({
      eventsState: (state) => state.visits.eventLogs,

      statePageData: (state) => state.stateObjectStatistics.data,
      stateFilter: (state) => state.stateObjectStatistics.filter,
      statePagination: (state) => state.stateObjectStatistics.pagination,
    }),
  },

  created() {
    this.getId();
    this.getState();
    this.getEvents();
  },
};
</script>
