<template>
  <Alert v-if="isFailureAddObject" type="error" prominent>
    {{ errorMessageAddObject }}
  </Alert>

  <Form
    ref="saveUser"
    v-model="object"
    :isPendingStore="isPendingAddObject"
    @SaveForm="onSave"
    @cancelForm="onCancel"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";

import Form from "../components/Form";
import Alert from "@/components/Alert";

import faceValidate from "@/mixins/faceValidate";

export default {
  name: "AddPerson",

  mixins: [faceValidate],

  components: { Form, Alert },

  data() {
    return {
      object: {},
    };
  },

  methods: {
    ...mapActions({
      addObjectAction: "object/addObject",

      resetAddObjectAction: "object/resetAddObject",
    }),

    onSave() {
      this.addObjectAction(this.object).then(this.onCancel);
    },

    onCancel() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      isPendingAddObject: (state) => state.object.isPendingAddObject,
      isFailureAddObject: (state) => state.object.isFailureAddObject,
      errorMessageAddObject: (state) => state.object.errorMessageAddObject,
    }),
  },

  unmounted() {
    this.resetAddObjectAction();
  },
};
</script>
