<template>
  <v-col class="pa-0">
    <v-data-table
      :headers="fields"
      :items="eventLogs"
      disable-sort
      :items-per-page="pagination.limit"
      :row-props="
        (row) => {
          return { class: indicator(row.item) };
        }
      "
      item-key="id"
    >
      <template #[`item.avatar`]="{ item }">
        <EventAvatar :item="item" />
      </template>

      <template #[`item.identity_types`]="{ item }">
        <EventPurposeType :item="item" />
        <EventIdentityTypes :item="item" />
      </template>

      <template #[`item.pass_type`]="{ item }">
        <EventPassType :item="item" />
        <EventActions :item="item" />
      </template>

      <template #[`item.content`]="{ item }">
        <EventContent :item="item" />
        <EventContentChips :item="item" />
      </template>

      <template #[`item.formatCreatedAt`]="{ item }">
        <EventContent
          :item="item"
          :name="{
            show: false,
          }"
          :purpose="{ show: false }"
          :temperature="{ show: false }"
          :formatCreatedAt="{
            show: true,
          }"
        />
      </template>

      <template #bottom>
        <DataTableFooterV2
          v-model="pagination"
          @update:modelValue="onPagination"
        />
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import DataTableFooterV2 from "@/components/DataTableFooterV2";

import correctVisit from "@/mixins/correctVisit";
import {
  EventPurposeType,
  EventIdentityTypes,
  EventAvatar,
  EventContent,
  EventContentChips,
  EventPassType,
  EventActions,
  EventIndicator,
} from "@/components/EventsTools";

export default {
  components: {
    DataTableFooterV2,
    EventAvatar,
    EventIdentityTypes,
    EventPurposeType,
    EventContent,
    EventContentChips,
    EventPassType,
    EventActions,
  },

  mixins: [correctVisit, EventIndicator],

  data() {
    return {
      eventLogs: [],

      fields: [
        {
          value: "pass_type",
          width: 8,
          cellProps: {
            class: "pl-2 pr-0 py-2",
          },
        },
        {
          value: "identity_types",
          width: 8,
          cellProps: {
            class: "pl-1 pr-0 py-2",
          },
        },
        {
          value: "avatar",
          width: 50,
          cellProps: {
            class: "py-2",
          },
        },
        {
          value: "content",
          title: this.$t("users.visitor").toUpperCase(),
          cellProps: {
            class: "py-2",
          },
        },
        {
          value: "formatCreatedAt",
          title: this.$t("users.visit_time").toUpperCase(),
          width: 50,
          align: "center",
        },
      ],

      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      getEventLogsAction: "visits/getEventLogs",
      setStatePaginationAction: "stateEventLogTable/setPagination",
    }),

    //Получение состояния страницы
    getState() {
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния страницы
    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };
      this.setStatePaginationAction(this.pagination);
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getEventLogs();
    },

    //Получение и обновление данных таблицы
    getEventLogs() {
      const params = {
        ...this.pagination,
        ...this.monitoringStateFilter,
      };

      this.getEventLogsAction(params).then((response) => {
        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;
          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }
        const list = [];
        this.eventLogsState.forEach((visit) => {
          list.push(this.correctVisit(visit, false));
        });
        this.eventLogs = list;
      });
    },
    connectWebSockets() {
      this.$emitter.on(this.$const.WS_CHANNELS.NEW_EVENT, (visit) => {
        this.onVisit(visit);
      });
    },
    disconnectedWebSockets() {
      this.$emitter.off(this.$const.WS_CHANNELS.NEW_EVENT);
    },
    onVisit(visit) {
      const correctVisit = this.correctVisit(visit, false);

      const isZonesFiltered =
        !this.monitoringStateFilter.zoneId ||
        correctVisit.zone_id == this.monitoringStateFilter.zoneId;

      const isZonesIncludeUser =
        !this.$auth.user().zones ||
        this.$auth.user().zones.includes(parseInt(correctVisit.zone_id));

      const isTypeFiltered =
        !this.monitoringStateFilter.profileType ||
        correctVisit.profile_type == this.monitoringStateFilter.profileType;

      const isRoleAllowed =
        !this.role.allowed_zones.length ||
        this.role.allowed_zones.includes(parseInt(correctVisit.zone_id));

      const isTemperature =
        !this.monitoringStateFilter.temp_mode ||
        correctVisit.event.temperature != "0.0";

      if (
        isZonesFiltered &&
        isZonesIncludeUser &&
        isTypeFiltered &&
        isRoleAllowed &&
        isTemperature
      ) {
        this.setItemToLogList(correctVisit);
      }
    },
    setItemToLogList(visit) {
      if (this.eventLogs.length) {
        const length = this.eventLogs.length;

        const isAddToArray =
          this.eventLogs.length &&
          this.pagination.page === 1 &&
          (this.$moment(visit.created_at).isAfter(
            this.$moment(this.eventLogs[this.eventLogs.length - 1].created_at)
          ) ||
            (this.$moment(visit.created_at).isSame(
              this.eventLogs[this.eventLogs.length - 1].created_at
            ) &&
              visit.id > this.eventLogs[this.eventLogs.length - 1].id));

        if (isAddToArray) {
          if (length >= this.pagination.limit) {
            this.eventLogs.splice(-1);
          }

          for (let i = 0; i < this.eventLogs.length; i++) {
            const arrVisit = this.eventLogs[i];
            if (
              this.$moment(visit.created_at).isAfter(
                this.$moment(arrVisit.created_at)
              )
            ) {
              this.eventLogs.splice(i, 0, visit);
              break;
            } else if (
              this.$moment(visit.created_at).isSame(arrVisit.created_at) &&
              visit.id > arrVisit.id
            ) {
              this.eventLogs.splice(i, 0, visit);
              break;
            }
          }
        }
      } else {
        this.eventLogs.push(visit);
      }
    },
  },

  computed: {
    ...mapState({
      eventLogsState: (state) => state.visits.eventLogs,
      monitoringStateFilter: (state) => state.stateMonitoring.filter,
      statePagination: (state) => state.stateEventLogTable.pagination,
    }),
    ...mapGetters({
      role: "user/getRole",
    }),
  },

  watch: {
    monitoringStateFilter() {
      this.pagination.page = 1;
      this.onPagination();
    },
  },

  mounted() {
    this.getState();
    this.getEventLogs();
    this.connectWebSockets();
  },

  unmounted() {
    this.disconnectedWebSockets();
  },
};
</script>
