<template>
  <Alert v-if="isFailureAddProfile" type="error" prominent>
    {{ errorMessageAddProfile }}
  </Alert>

  <Form
    v-model="profile"
    :isPending="isPendingAddProfile"
    :defaultTypeId="type_id"
    @SaveForm="onSave"
    @cancelForm="onCancel"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  name: "AddProfile",

  emits: ["update:modelValue"],

  components: { Form, Alert },

  data() {
    return {
      profile: {},
      object_id: null,
      type_id: null,
    };
  },

  methods: {
    ...mapActions({
      addProfileAction: "profiles/addProfile",
      resetAddProfileAction: "profiles/resetAddProfile",
    }),

    getData() {
      this.object_id = this.$route.params.object_id;
      this.type_id = history.state.type_id;
    },

    onSave() {
      const params = {
        ...this.profile,
        objects_id: parseInt(this.object_id),
      };
      this.addProfileAction(params).then(this.onCancel);
    },

    onCancel() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      isPendingAddProfile: (state) => state.profiles.isPendingAddProfile,
      isFailureAddProfile: (state) => state.profiles.isFailureAddProfile,
      errorMessageAddProfile: (state) => state.profiles.errorMessageAddProfile,
    }),
  },

  created() {
    this.getData();
  },

  unmounted() {
    this.resetAddProfileAction();
  },
};
</script>
